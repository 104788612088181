export function getLang(){
  let supportedLangs = ["en", "es", "pt", "th", "vi"]
//gets Windows language settings for IE and Edge
//returns array of language preferences
  var lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

//languages formatted as en-US, zh-CN; Need to split off language code from country
  if (lang.indexOf('-') !== -1){
      lang = lang.split('-')[0];
  }
  if (lang.indexOf('_') !== -1){
      lang = lang.split('_')[0];
  }

//Checks for browsers language settings, sets language to that if it's not English
  if (navigator.language.split('-')[0] !== "en") {
      lang = navigator.language.split('-')[0];
  }
//Final Check, if not supported language, default to English
  let currSupportedLang = false;
  for(let i=0; i< supportedLangs.length; i++){
      if (lang === supportedLangs[i]){
          currSupportedLang = true;
      }
  }
  if(currSupportedLang !== true){
      lang = "en";
  }
  return lang;
}