let EntPwdExpiry2_3DaysText = 
{
    "ignore":
    {
        "en": "Note: If you have recently taken steps to reset your password, this notice can be ignored.",
        "es": "Nota: Si usted ya ha realizado recientemente el cambio de su contraseña, puede ignorar este mensaje.",
        "pt": "Nota: Se você recentemente fez o reset de sua senha, poderá ignorar esta notificação.",
        "th": "หมายเหตุ: หากคุณมีการดำเนินการเมื่อเร็ว ๆ นี้ ในการตั้งค่ารหัสผ่านของคุณแล้ว คุณสามารถเพิกเฉยต่อการแจ้งเตือนนี้",
        "vi": "Lưu ý: Nếu bạn đã thực hiện thay đổi mật khẩu trong thời gian gần đây, hãy bỏ qua thông báo này."

    },
    "additionalBodyText":
    {
        "en": 
        [
            "Liberty Mutual security policy requires that you change your password on a regular basis. By waiting until after your password expires to update your password, you risk potential business interruption and time lost while waiting for technical assistance.",
            "Internal Employees: If you have a Safeco 3x3 ID",
            ", please follow the additional steps below to change this password, as well."

        ],
        "es": 
        [
            "La política de seguridad de Liberty Mutual requiere que usted cambie su contraseña de manera regular. Esperar a que la contraseña expire para luego cambiarla podría generar una interrupción en su trabajo cotidiano y la pérdida de tiempo mientras espera por asistencia técnica.",
            "Empleado interno: Si usted tiene un Usuario Safeco 3x3",
            ", por favor siga las instrucciones adicionales para cambiar su contraseña."

        ],
        "pt": 
        [
            "A política de segurança da Liberty Mutual requer que a mudança de senha seja executada em bases regulares. Ao esperar a expiração da sua senha para então trocá-la, você acarretará o risco de interrupções de negócio e perda de tempo enquanto espera pelo suporte técnico para resolver esta questão.",
            "Funcionário interno: Se você tiver um ID da Safeco 3x3",
            ", por gentileza siga os passos adicionais abaixo, para alterar sua senha."

        ],
        "th": 
        [
            "นโยบายความปลอดภัยของลิเบอร์ตี้ คุณต้องเปลี่ยนรหัสผ่านของคุณเป็นประจำ ถ้ารอจนกว่าจะได้รับข้อความว่า รหัสผ่านของคุณหมดอายุ, คุณมีความเสี่ยงที่อาจเกิดการหยุดชะงักทางธุรกิจและสูญเสียเวลาในขณะที่รอความช่วยเหลือด้านเทคนิค.",
            "พนักงาน ภายใน: หากคุณมีรหัส 3x3 Safeco",
            " โปรดทำตามขั้นตอนเพิ่มเติมที่ด้านล่างเพื่อเปลี่ยนรหัสผ่านของคุณในเวลานี้. "

        ],
        "vi": 
        [
            "Chính sách bảo mật của Liberty Mutual yêu cầu bạn phải thay đổi mật khẩu thường xuyên. Nếu bạn không thay đổi mật khẩu khi nhận được thông báo mật khẩu đến hạn thay đổi, bạn sẽ gặp rủi ro gián đoạn trong công việc cũng như mất thời gian chờ đợi yêu cầu hỗ trợ từ phía kỹ thuật.",
            "Nhân viên Liberty: Nếu bạn có Tài khoản Safeco 3x3",
            ", hãy thực hiện theo các bước dưới đây để thay đổi mật khẩu này."

        ]

    },
    "safecoTitle":
    {
        "en": "Safeco Users",
        "es": "Usuarios Safeco",
        "pt": "Usuários Safeco",
        "th": "ผู้ใช้ Safeco",
        "vi": "Người dùng Safeco"

    },
    "safecoSteps":
    {
        "en": 
        [
            "For select individuals with a Safeco 3x3 Account",
            "Some Personal Insurance business applications still require employees to provide their legacy Safeco 3x3 ID and password.  If you do need to use your Safeco 3x3 ID, you must maintain its password; it is not currently included in the above ",
            " process. ",
            "To change your Safeco 3x3 password, please visit ",
            "this solution",
            " and follow the steps until you receive confirmation that your password has been updated. This will update the password on both legacy Safeco platforms (Windows and Mainframe).",
            "After changing your Safeco 3x3 password, close and then re-start any applications or resources which may be active and use the Safeco 3x3 ID and password"
        ],
        "es":
        [
            "Para individuos con cuenta de Usuario Safeco 3x3",
            "Algunas aplicaciones de negocio de Personal Insurance todavía requieren que los empleados ingresen su antiguo Usuario Safeco 3x3 y su contraseña. Si usted necesita utilizar su Usuario Safeco 3x3, debe gestionar su contraseña, ya que la misma no se encuentra incluida dentro del proceso de ",
            ".",
            "Para cambiar la contraseña de su usuario Safeco 3x3, por favor ",
            "haga clic aquí. ",
            "Siga las instrucciones hasta que usted reciba confirmación de que su contraseña ha sido actualizada. ",
            "Esto actualizará la contraseña en ambas plataformas legacy de Safeco (Windows and Mainframe). "
        ],
        "pt":
        [
            "Para pessoas com uma conta Safeco 3x3",
            "Algumas aplicações de negócio de Seguro Pessoal ainda requerem que os funcionários utilizem o ID e senha legados da Safeco 3x3. Se você realmente precisa usar seu ID da Safeco 3x3, você próprio deverá manter sua senha, pois este procedimento não está incluído no processo via ",
            " descrito acima. ",
            "Para alterar sua senha do usuário Safeco 3x3, por favor, ",
            "clique aqui",
            ". Navegue através das páginas até receber a confirmação de que sua senha foi alterada. Isto atualizará a senha nas plataformas Windows e Mainframe da Safeco.",
            "Após alterar sua senha da Safeco 3x3, feche e reinicie as aplicações ou recursos que possam usar este ID e senha."
        ],
        "th": 
        [
            "สำหรับบุคคลที่มีบัญชี 3x3 Safeco",
            "บางธุรกิจประกันภัยส่วนบุคคลยังใช้ระบบ Safeco ID 3x3 และรหัสผ่านของพวกเขา หากคุณจำเป็นต้องใช้ Safeco ID 3x3 ของคุณ คุณจะต้องรักษารหัสผ่านของคุณด้วย มันไม่ได้รวมอยู่ในกระบวนการเปลี่ยนรหัสผ่านข้างต้นผ่าน ",
            " process. ",
            "ถ้าต้องการเปลี่ยนรหัสผ่าน Safeco 3x3 ของคุณ, ",
            "กรุณาเยี่ยมชมที่ลิงค์นี้ ",
            " และทำตามขั้นตอนจนกว่าคุณจะได้รับการยืนยันว่ารหัสผ่านของคุณได้รับการปรับปรุง. ส่วนนี้จะปรับปรุงรหัสผ่านได้ทั้งบนแพลตฟอร์ม Safeco (Windows และเมนเฟรม) ",
            "หลังจากเปลี่ยนรหัสผ่านของคุณ 3x3 Safeco ให้เริ่มต้นใหม่การใช้งานใด ๆ หรือทรัพยากรซึ่งอาจจะใช้งานและใช้รหัส 3x3 Safeco และรหัสผ่าน"
        ],
        "vi": 
        [
            "Được gửi đến các cá nhân có Tài khoản Safeco 3x3 ",
            "Một số ứng dụng Bảo hiểm Cá nhân vẫn yêu cầu nhân viên cung cấp mật khẩu và Tài khoản Safeco 3x3 cũ. Nếu bạn cần sử dụng Tài khoản Safeco 3x3, bạn phải ghi nhớ mật khẩu của Tài khoản này; mật khẩu không thể thay đổi bằng ",
            ".",
            "Để thay đổi mật khẩu Safeco 3x3 của bạn, vui lòng truy cập ",
            "https://outside.safeco.com/outside/pwchange/pwchange.asp",
            ", thực hiện theo các bước cho đến khi bạn nhận được xác nhận rằng mật khẩu của bạn đã được thay đổi. ",
            "Sau khi bạn đã thay đổi mật khẩu Safeco 3x3, vui lòng đóng và khởi động lại tất cả các ứng dụng đang sử dụng Tài khoản và mật khẩu này."
        ]

    },
    "safecoSupportTitle":
    {
        "en": "Contacting Support",
        "es": "Soporte Técnico",
        "pt": "Contato com o Suporte",
        "th": "ติดต่อฝ่ายสนับสนุน",
        "vi": "Liên hệ Hỗ trợ"

    },
    "safecoContact":
    {
        "en": 
        [
            "If you have a problem or question, please visit the ",
            "Help Yourself Desk (HYD)",
            " and enter the key phrase ",
            ". The HYD also provides options for contacting the IT Help Desk.",
        ],
        "es": 
        [
            "Si usted tiene un problema o pregunta, por favor visite el sitio ",
            "Help Yourself Desk (HYD)",
            " e ingrese la palabra clave ",
            ". El Help Yourself Desk también provee opciones de cómo contactar al IT Help Desk.",
        ],
        "pt": 
        [
            "Caso você tenha um problema ou questão, por gentileza visite o ",
            "Help Yourself Desk (HYD)",
            " e forneça a \"frase-chave\" ",
            ". O HYD provê ainda opções para entrar em contato com o IT Help Desk.",
        ],
        "th": 
        [
            "ถ้าคุณมีปัญหาหรือคำถามกรุณาเยี่ยมชม ",
            "Help Yourself Desk (HYD)",
            " โดยการพิมพ์คำหลัก ",
            ". HYD นอกจากนี้ยังมีตัวเลือกสำหรับการติดต่อ Helpdesk.",
        ],
        "vi": 
        [
            "Nếu bạn gặp phải bất kỳ vấn đề hay câu hỏi nào khác, hãy truy cập ",
            "Thư viện hướng dẫn trợ giúp (HYD)",
            " và nhập cụm từ khóa ",
            ". HYD cũng cung cấp tùy chọn liên hệ với Bộ phận Hỗ trợ CNTT.",
        ]

    },
    "functionalityAvail":
    {
        "en": " Functionality available to Liberty Mutual employees on a company issued computer.",
        "es": " Esta funcionalidad solo está disponible para Empleados de Liberty Mutual en un dispositivo corporativo",
        "pt": " Funcionalidade disponível apenas para funcionários Liberty Mutual que estejam em um computador da companhia.",
        "th": " ฟังก์ชั่นสำหรับ เครื่องที่ออกให้โดย Liberty Mutual เท่านั้น.",
        "vi": " Tất cả nhân viên Liberty Mutual đều sử dụng được tính năng này trên máy tính của công ty."

    },
}
module.exports = EntPwdExpiry2_3DaysText;