import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Links from '../../text-content/Links';
import ModalText from '../../text-content/ModalText';
import ButtonText from '../../text-content/ButtonText';
import { getLang } from '../../actions/langActions';

class AlertDialog extends React.Component {
  state = {
    open: true,
  };


  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    let userLang = getLang();
    let onLoadTitle = ModalText.onLoadTitle[userLang];
    let continueToPW = ButtonText.continueToPW[userLang];
    let employeeTypeList = ModalText.employeeTypeList[userLang];
    let lpsText = ModalText.lpsText[userLang];
    let countryList = ModalText.countryList[userLang];
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          <div className="modal-title">
            <div><i className="fas fa-exclamation-circle att-icon"></i></div>
            <div className="modal-title-text">{onLoadTitle}</div>
            <div><i className="fas fa-exclamation-circle att-icon"></i></div>
          </div>
          </DialogTitle>
          <DialogContent component={'span'} className="modal-scroll" id="onload-modal">
            <DialogContentText id="alert-dialog-description" component={'span'}>
              <p>{employeeTypeList[0]}<strong>{employeeTypeList[1]}</strong>{employeeTypeList[2]}<strong>{employeeTypeList[3]}</strong>{employeeTypeList[4]}<strong>{employeeTypeList[5]}</strong>{employeeTypeList[6]}</p>
              <div className='row countries-modal'>
              <ul className='countries-list'>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/bermuda.png')} alt ='bermuda-flag'/> {countryList[11]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/brazil.png')} alt ='brazil-flag'/> {countryList[0]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/chile.png')} alt ='chile-flag' /> {countryList[1]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/china.png')} alt ='china-flag' /> {countryList[2]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/colombia.png')} alt ='colombia-flag' /> {countryList[3]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/ecuador.png')} alt ='ecuador-flag' /> {countryList[4]}</li>
                </ul>
                <ul className='countries-list'>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/ireland.png')} alt ='ireland-flag' /> {countryList[5]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/malaysia.png')} alt ='malaysia-flag' /> {countryList[6]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/portugal.png')} alt ='portugal-flag' /> {countryList[7]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/russia.png')} alt ='russia-flag' /> {countryList[8]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/spain.png')} alt ='spain-flag' /> {countryList[9]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/venezuela.png')} alt ='venezuela-flag' /> {countryList[10]}</li>
              </ul>
              </div>
              <p>{lpsText[0]}<a href={Links.lps}>{lpsText[1]}</a>{lpsText[2]}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions component={'span'}>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              {continueToPW}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;