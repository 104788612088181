import React, { Component } from 'react';
import '../../styles/application.css';
import { getLang } from '../../actions/langActions';
import HeaderText from '../../text-content/HeaderText';

class Subheader extends Component {

render(){
  let userLang = getLang()
  let subHeader= HeaderText.subHeader[userLang]
        return (
          <div className="sub-header">
            <h2 id="app-subtitle">{subHeader}</h2>
        </div>
        );
      };
    };
    
export default Subheader;