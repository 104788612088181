import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import HomePage from './home/HomePage';
import EntPwdExpiry2_3Days from './email-notifications/EntPwdExpiry2_3Days';
import EntPwdExpiry5_21Days from './email-notifications/EntPwdExpiry5_21Days';
import DoesNotExist from './email-notifications/DoesNotExist';
import CredRedirect from './cred-provider/CredRedirect';
import NewEntPwdExpiry1Day from './email-notifications/NewEntPwdExpiry1Day';
import NewEntPwdExpiryMoreThan1Day from './email-notifications/NewEntPwdExpiryMoreThan1Day';

class App extends Component {
  render() {
    return (
      <div id="root">
        <BrowserRouter>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/Welcome" component={HomePage} />

            <Route path="/notifications/EntPwdExpiry2_3Days_EN.html" render={(props) => <EntPwdExpiry2_3Days {...props} language="en" />} />
            <Route path="/notifications/EntPwdExpiry2_3Days_ES.html" render={(props) => <EntPwdExpiry2_3Days {...props} language="es" />} />
            <Route path="/notifications/EntPwdExpiry2_3Days_PT.html" render={(props) => <EntPwdExpiry2_3Days {...props} language="pt" />} />
            <Route path="/notifications/EntPwdExpiry2_3Days_TH.html" render={(props) => <EntPwdExpiry2_3Days {...props} language="th" />} />
            <Route path="/notifications/EntPwdExpiry2_3Days_VN.html" render={(props) => <EntPwdExpiry2_3Days {...props} language="vi" />} />

            <Route path="/notifications/EntPwdExpiry5_21Days_EN.html" render={(props) => <EntPwdExpiry5_21Days {...props} language="en" />} />
            <Route path="/notifications/EntPwdExpiry5_21Days_ES.html" render={(props) => <EntPwdExpiry5_21Days {...props} language="es" />} />
            <Route path="/notifications/EntPwdExpiry5_21Days_PT.html" render={(props) => <EntPwdExpiry5_21Days {...props} language="pt" />} />
            <Route path="/notifications/EntPwdExpiry5_21Days_TH.html" render={(props) => <EntPwdExpiry5_21Days {...props} language="th" />} />
            <Route path="/notifications/EntPwdExpiry5_21Days_VN.html" render={(props) => <EntPwdExpiry5_21Days {...props} language="vi" />} />

            <Route path="/Welcome/CredProvider.aspx" component={CredRedirect} />

            <Route path="/notifications/TH_MCDC_Com1_EN.html" component={DoesNotExist} />
            <Route path="/notifications/TH_MCDC_Com2_EN.html" component={DoesNotExist} />
            <Route path="/notifications/TH_MCDC_Com3_EN.html" component={DoesNotExist} />
            <Route path="/notifications/TH_MCDC_Pilot_Com1_EN.html" component={DoesNotExist} />
            <Route path="/notifications/TH_MCDC_Pilot_Com2_EN.html" component={DoesNotExist} />
            <Route path="/notifications/TH_MCDC_Pilot_Com3_EN.html" component={DoesNotExist} />
            <Route path="/notifications/VN_MCDC_Com1_EN.html" component={DoesNotExist} />
            <Route path="/notifications/VN_MCDC_Com2_EN.html" component={DoesNotExist} />
            <Route path="/notifications/VN_MCDC_Com3_EN.html" component={DoesNotExist} />
            <Route path="/notifications/VN_MCDC_Pilot_Com1_EN.html" component={DoesNotExist} />
            <Route path="/notifications/VN_MCDC_Pilot_Com2_EN.html" component={DoesNotExist} />
            <Route path="/notifications/VN_MCDC_Pilot_Com3_EN.html" component={DoesNotExist} />

            <Route path="/notifications/EntPwdExpiry1Day_EN.html" render={(props) => <NewEntPwdExpiry1Day {...props} language="en" />} />
            <Route path="/notifications/EntPwdExpiry1Day_ES.html" render={(props) => <NewEntPwdExpiry1Day {...props} language="es" />} />
            <Route path="/notifications/EntPwdExpiry1Day_PT.html" render={(props) => <NewEntPwdExpiry1Day {...props} language="pt" />} />
            <Route path="/notifications/EntPwdExpiry1Day_TH.html" render={(props) => <NewEntPwdExpiry1Day {...props} language="th" />} />
            <Route path="/notifications/EntPwdExpiry1Day_VN.html" render={(props) => <NewEntPwdExpiry1Day {...props} language="vi" />} />

            <Route path="/notifications/EntPwdExpiry2_21Days_EN.html" render={(props) => <NewEntPwdExpiryMoreThan1Day {...props} language="en" />} />
            <Route path="/notifications/EntPwdExpiry2_21Days_ES.html" render={(props) => <NewEntPwdExpiryMoreThan1Day {...props} language="es" />} />
            <Route path="/notifications/EntPwdExpiry2_21Days_PT.html" render={(props) => <NewEntPwdExpiryMoreThan1Day {...props} language="pt" />} />
            <Route path="/notifications/EntPwdExpiry2_21Days_TH.html" render={(props) => <NewEntPwdExpiryMoreThan1Day {...props} language="th" />} />
            <Route path="/notifications/EntPwdExpiry2_21Days_VN.html" render={(props) => <NewEntPwdExpiryMoreThan1Day {...props} language="vi" />} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

