import React, { Component } from 'react';
import '../../styles/css/new-notification.css';
import NewEntPwdExpiry1DayText from '../../text-content/email-notifications/NewEntPwdExpiryDayText';

class NewEntPwdExpiry1Day extends Component {
  constructor(props){
    super(props);
    this.state = {
      currLang: this.props.language
    };
  }
  handleClick = language => e => {
    e.preventDefault()
    this.setState({currLang: language})
  }
render(){
  let userLang = this.state.currLang
  let toEmployees= NewEntPwdExpiry1DayText.toEmployees[userLang]
  let aMessage= NewEntPwdExpiry1DayText.aMessage[userLang]
  let pwTitle1Day= NewEntPwdExpiry1DayText.pwTitle1Day[userLang]
  let instructions= NewEntPwdExpiry1DayText.instructions[userLang]
  let pillars= NewEntPwdExpiry1DayText.pillars[userLang]
  let responsibleDefender= NewEntPwdExpiry1DayText.responsibleDefender[userLang]
  let clickFor= NewEntPwdExpiry1DayText.clickFor[userLang]
  let langs= NewEntPwdExpiry1DayText.langs[userLang]




    return (
      <div id="emailContainer">
            <div className="mainDiv">
                <div className="employeeHeader">{toEmployees}</div>
                <div className="messageFromITHD">
                    <img src={require('./../../assets/email-notification/img/logo.png')} alt="Responsible Defender Logo" id="rdLogo" />
                    <div className="ithdText">
                        <div className="ithdTop">{aMessage[0]}</div>
                        <div className="ithdBottom">{aMessage[1]}</div>
                    </div>
                </div>
	            <div className="allContent">
		            <div className="mainBody">
			            <div className="header2C">{pwTitle1Day}</div>	
			            <div className="bodytext">{instructions[0]}<a href="https://mypassword.lmig.com/" className="emailLink">myPassword</a>{instructions[1]}</div>	
			            <div className="bodytext">{pillars[0]}<a href="https://myliberty.lmig.com/en-us/EmployeeCenter/infosec/Pages/Pillars-of-Data-Protection.aspx" className="emailLink">{pillars[1]}</a>{pillars[2]}</div>	
			            <div className="header2C" id="rdText">{responsibleDefender}</div>	
		            </div>
		            <div className="translations">
			            <div className="bodytext" id="translationText">{clickFor}</div>
			            <div className="translationFlags">
                        <img src={require('./../../assets/email-notification/img/BR_Flag.gif')} alt="brazil-flag" />&nbsp;
                    <img src={require('./../../assets/email-notification/img/PT_Flag.gif')} alt="portugal-flag" />&nbsp;
                    <a href="/" onClick={this.handleClick('pt')}><span className="emailLink">{langs[0]}</span></a>&nbsp; &nbsp;
                    <img src={require('./../../assets/email-notification/img/CL_Flag.gif')} alt="colombia-flag" />&nbsp;
                    <img src={require('./../../assets/email-notification/img/EC_Flag.gif')} alt="ecudador-flag" />&nbsp;
                    <img src={require('./../../assets/email-notification/img/ES_Flag.gif')} alt="spain-flag" />&nbsp;
                    <img src={require('./../../assets/email-notification/img/VZ_Flag.gif')} alt="venezuela-flag" />&nbsp;
                    <a href="/" onClick={this.handleClick('es')}><span className="emailLink">{langs[1]}</span></a>&nbsp; &nbsp;
                    <img src={require('./../../assets/email-notification/img/TH_Flag.gif')} alt="thai-flag" />&nbsp;
                    <a href="/" onClick={this.handleClick('th')} ><span className="emailLink">{langs[2]}</span></a>&nbsp; &nbsp;
                    <img src={require('./../../assets/email-notification/img/VN_Flag.gif')} alt="vietnam-flag" />&nbsp;
                    <a href="/" onClick={this.handleClick('vi')} ><span className="emailLink">{langs[3]}</span></a>&nbsp; &nbsp;
			            </div>
		            </div>
	            </div>
                <div className="footer">
                    <img src={require('./../../assets/email-notification/img/medalion.png')}  alt="Liberty Mutual Logo" id="libertyLogo"/>
                </div>
            </div>
          </div>
        );
      };
    };
    
export default NewEntPwdExpiry1Day;