import React, { Component } from 'react';
import '../../styles/application.css';
import PWText from '../../text-content/PWText';
import PWInstructions from '../instructions/PWInstructions';
import UserWarning from '../modals/UserWarning';
import { getLang } from '../../actions/langActions';

class PWCard extends Component {
  constructor(props){
    super(props);
    this.state = {panel: 'panel1'};
  }
  handleCloseContinue = value => {
    this.setState({panel: 'panel2' });
  };

render(){
  let userLang = getLang();
  let subTitle = PWText.subTitle[userLang];
  let title = PWText.title[userLang];
        return (
          <div className="card-body">
          <div>
            <h2 className="card-header">{title}</h2>
            <div className="card-subheader">
              <em><small><strong>{subTitle[0]}</strong>{subTitle[1]}</small></em>
            </div>
            <hr />
          <PWInstructions expanded={this.state.panel}/>
          </div>
          <div className='button-wrapper'>
            <UserWarning action={this.handleCloseContinue} />
          </div>
        </div>
        );
      };
    };
    
export default PWCard;