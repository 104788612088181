import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppButton from '../buttons/AppButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PWText from '../../text-content/PWText';
import '../../styles/application.css';
import ButtonText from '../../text-content/ButtonText';
import ModalText from '../../text-content/ModalText';
import { getLang } from '../../actions/langActions';
import Links from '../../text-content/Links';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

class SimpleDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };
  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;
    let userLang = getLang();
    let preSteps = PWText.preSteps[userLang];
    let yesContinue = ButtonText.yesContinue[userLang];
    let goBack = ButtonText.goBack[userLang];
    let userWarningTitle = ModalText.userWarningTitle[userLang]
    let microsoftAccManagement = Links.microsoftAccManagement;

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
               <DialogTitle id="alert-dialog-title">
               <div className="modal-title">
                    <div><i className="fas fa-exclamation-triangle warning-triangle"></i></div>
                    <div className="modal-title-text">{userWarningTitle}</div>
                    <div><i className="fas fa-exclamation-triangle warning-triangle"></i></div>
                </div>
            </DialogTitle>
          <DialogContent component={'span'}>
            <DialogContentText component={'span'} id="alert-dialog-description">
            <ol>
              <li><strong>{preSteps[0]}</strong>{preSteps[1]}</li>
              <li><strong>{preSteps[2]}</strong>{preSteps[3]}</li>
            </ol>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button onClick={(param) =>this.handleListItemClick('no')} color="primary">
             {goBack}
            </Button>
            <a href={microsoftAccManagement} target="_blank" rel="noopener noreferrer"><Button onClick={(param) =>this.handleListItemClick('yes')} color="primary" autoFocus>
             {yesContinue}
            </Button></a>
          </DialogActions>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class UserWarning extends React.Component {
  state = {
    open: false,
    selectedValue: null,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
    if(value === 'yes'){
      this.props.action();
    }
    else{
      return null
    }

  };


  render() {
    let userLang = getLang();
    let managePW = ButtonText.managePW[userLang];
    return (
      <div>
    <div onClick={this.handleClickOpen}><AppButton title={managePW} /></div>
        <SimpleDialogWrapped
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default UserWarning;