import React, { Component } from 'react';
import Header from '../headers/Header';
import PWCard from '../cards/PWCard';
import UnlockCard from '../cards/UnlockCard';
import Subheader from '../headers/Subheader';
import OnLoadModal from '../modals/OnLoadModal';
import '../../styles/application.css';
import { getLang } from '../../actions/langActions';
import PWText from '../../text-content/PWText';
import UnlockText from '../../text-content/UnlockText';

class HomePage extends Component {
    render() {
        let userLang = getLang();
        let PWTitle = PWText.title[userLang];
        let UnlockTitle = UnlockText.title[userLang];
      return (
        <div>
            <OnLoadModal />
            <Header />
            <Subheader />
            <div id="root-content">
                 <div className="row">
                    <div className="col-lg-6 main-card">
                        <PWCard title={PWTitle}/>
                    </div>
                    <div className="col-lg-6 main-card">
                        <UnlockCard title={UnlockTitle}/>
                    </div>
                </div>
            </div>
        </div>
      );
    }
  }
  
  export default HomePage;
  