let UnlockText = 
{
    "title":
    {
        "en": "Unlock Account",
        "es": "Desbloquear cuenta",
        "pt": "Desbloquear conta",
        "th": "ปลดล็อคบัญชี",
        "vi": "Mở khoá tài khoản"

    },
    "subTitle":
        {
            "en": "You'll keep your existing password and we'll unlock your account so you can sign in again.",
            "es": "Mantendrá su contraseña actual y desbloquearemos su cuenta para que pueda iniciar sesión nuevamente.",
            "pt": "Você manterá sua password atual e desbloquearemos sua conta para poder fazer login novamente.",
            "th": "คุณจะเก็บรหัสผ่านที่มีอยู่ของคุณและเราจะปลดล็อคบัญชีของคุณเพื่อให้คุณสามารถลงชื่อเข้าใช้อีกครั้ง",
            "vi": "Bạn sẽ giữ mật khẩu hiện tại của mình và chúng tôi sẽ mở khóa tài khoản của bạn để bạn có thể đăng nhập lại.",
        },

    "instructions":
    {
        "en": [
            "Enter your ",
            "enterprise email ",
            "and correct CAPTCHA text. Click ",
            "Next",
            "Select the option: ",
            "I know my password, but still can't sign in",
            "Click ",
            "Next",
            "Select your preferred method to verify your account and follow the on-screen instructions. You will receive a verification code you will need to submit in order to unlock your account."
        ],
        "es": [
            "Introduzca su ",
            "correo electrónico empresarial ",
            "y corrija el texto CAPTCHA. Haga clic ",
            "Siguiente",
            "Seleccione la opción: ",
            "Sé mi contraseña, pero todavía no puedo iniciar sesión ",
            "Haga clic ",
            "Siguiente",
            "Seleccione su método preferido para verificar su cuenta y siga las instrucciones en pantalla. Recibirá un código de confirmación que deberá enviar para desbloquear su cuenta."
        ],
        "pt": [
            "Insira o seu ",
            "email corporativo ",
            "e corrija o texto CAPTCHA. Clique ",
            "Próximo",
            "Selecione a opção:",
            " Eu sei minha password, mas não consigo entrar ",
            "Clique ",
            " Próximo",
            "Selecione seu método preferido para verificar sua conta e siga as instruções no ecrã. Você receberá um código de confirmação que precisará enviar para desbloquear sua conta."

        ],
        "th": [
            "ใส่ ",
            "อีเมลองค์กร ของคุณ ",
            "และแก้ไขข้อความ CAPTCHA คลิก ",
            "ต่อไป",
            "เลือกตัวเลือก: ",
            "ฉันรู้รหัสผ่านของฉัน แต่ยังไม่สามารถลงชื่อเข้าใช้ได้",
            "คลิก ",
            "ต่อไป",
            "เลือกวิธีที่คุณต้องการเพื่อยืนยันบัญชีของคุณและทำตามคำแนะนำบนหน้าจอ คุณจะได้รับรหัสยืนยันคุณจะต้องส่งเพื่อปลดล็อคบัญชีของคุณ"

        ],
        "vi": [
            "Nhập của bạn ",
            "email doanh nghiệp ",
            "và nhập chính xác văn bản CAPTCHA. Nhấp chuột ",
            "Kế tiếp",
            "Chọn tùy chọn: ",
            "Tôi biết mật khẩu của mình, nhưng vẫn không thể đăng nhập",
            "Nhấp chuột ",
            " Kế tiếp",
            "Chọn phương thức ưa thích của bạn để xác minh tài khoản của bạn và làm theo các hướng dẫn trên màn hình. Bạn sẽ nhận được mã xác nhận bạn cần gửi để mở khóa tài khoản của mình."
        ]
    },
    "instrHeader":
    {
        "en": "To Unlock Your Account",
        "es": "Para desbloquear su cuenta",
        "pt": "Para desbloquear sua conta",
        "th": "เพื่อปลดล็อกบัญชีของคุณ",
        "vi": "Để mở khóa tài khoản của bạn"

    },
    "clickButtonInstr":
    {
        "en": "Click the 'Unlock Account' button below and follow these instructions:",
        "es": "Haga clic en el botón 'Desbloquear cuenta' a continuación y siga estas instrucciones:",
        "pt": "Clique no botão 'Desbloquear conta' abaixo e siga estas instruções:",
        "th": "คลิกปุ่ม 'ปลดล็อกบัญชี' ด้านล่างและทำตามคำแนะนำเหล่านี้:",
        "vi": "Nhấp vào nút 'Mở khóa tài khoản' bên dưới và làm theo các hướng dẫn sau:"

    }

}
module.exports = UnlockText;