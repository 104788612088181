import React, { Component } from 'react';
import '../../styles/application.css';

class AppButton extends Component {

  render() {
    return (
      <div className='button-div'>
        <button className='default-button'>{this.props.title}</button>
      </div>
    );
  };
};

export default AppButton;