let FAQText = 
{
    "lpsQuestion":
    {
        "en": "Should I use myPassword or Liberty Password Sync to manage my enterprise account?",
        "es": "¿Debo usar myPassword o Liberty Password Sync para administrar mi cuenta de empresa?",
        "pt": "Devo usar myPassword ou Liberty Password Sync para gerir a minha conta corporativa?",
        "th": "ฉันควรใช้ myPassword หรือ Liberty Password Sync เพื่อจัดการบัญชีองค์กรของฉันหรือไม่",
        "vi": "Tôi có nên sử dụng myPassword hoặc Liberty Password Sync để quản lý tài khoản doanh nghiệp của mình không?"

    },
    "lpsAnswer":
    {
        "en": ["If you do not fall into any of the categories above, please continue to use ","myPassword", " to change your password or unlock your account."],
        "es": ["Si no pertenece a ninguna de las categorías anteriores, continúe usando ","myPassword", " para cambiar su contraseña o desbloquear su cuenta."],
        "pt": ["Se você não se enquadra em nenhuma das categorias acima, continue a usar ","myPassword", " para alterar / redefinir sua password ou desbloquear sua conta."],
        "th": ["หากคุณไม่ได้อยู่ในหมวดหมู่ใด ๆ ข้างต้นโปรดใช้ต่อไป ","myPassword", " เพื่อเปลี่ยน / รีเซ็ตรหัสผ่านของคุณหรือปลดล็อคบัญชีของคุณ"],
        "vi": ["Nếu bạn không thuộc bất kỳ danh mục nào ở trên, vui lòng tiếp tục sử dụng ", "myPassword", " để thay đổi / đặt lại mật khẩu hoặc mở khóa tài khoản của bạn."]

    },
    "whyMFAQuestion":
    {
        "en": "What is MFA and why do I need it?",
        "es": "¿Qué es MFA y por qué lo necesito?",
        "pt": "O que é o MFA e por que eu preciso disso?",
        "th": "MFA คืออะไรและทำไมฉันต้องใช้",
        "vi": "MFA là gì và tại sao tôi cần nó?"

    },
    "whyMFAAns":
    {
        "en": [
            "MFA stands for ",
            "Multifactor Authentication",
            ". Multifactor Authentication is a two-step verification process used to confirm a user's identity by using something the user knows ",
            "(example: a password)",
            ", as well as a second 'factor' ",
            "(example: a token or code)",
            "In order to use the myPassword tool to manage your enterprise account, you ",
            "MUST",
            " register a method to receive a second 'factor' to be used to confirm your identity."
            ],
        "es": [
            "MFA significa ",
            "Autenticación multifactor",
            ". La autenticación multifactorial es un proceso de verificación de dos pasos que se utiliza para confirmar la identidad de un usuario utilizando algo que el usuario sabe ",
            "(ejemplo: una contraseña)",
            ", así como un segundo 'factor' ",
            "(ejemplo: un token o código)",
            "Para utilizar la herramienta myPassword para administrar su cuenta de empresa, debe ",
            "DEBE",
            " registar un método para recibir un segundo 'factor' que se usará para confirmar su identidad."
        ],
        "pt": [
            "MFA significa ",
            "Autenticação Multifactor",
            ". Autenticação multifatorial é um processo de verificação em duas etapas usado para confirmar a identidade de um utilizador usando algo que o usuário conhece ",
            "(exemplo: uma password)",
            ", bem como um segundo ‘fator’ ",
            "(exemplo: um token ou código)",
            "Para usar a ferramenta myPassword para gerir sua conta corporativa, você ",
            "DEVE",
            " registrar um método para receber o segundo 'fator' a ser usado para confirmar sua identidade."            
        ],
        "th": [
            "MFA หมายถึง ",
            "การตรวจสอบ Multifactor",
            ". การตรวจสอบ Multifactor เป็นกระบวนการตรวจสอบสองขั้นตอนที่ใช้ในการยืนยันตัวตนของผู้ใช้โดยใช้บางสิ่งที่ผู้ใช้รู้ ",
            "(ตัวอย่าง: รหัสผ่าน)",
            ", เช่นเดียวกับ 'ปัจจัย' ที่สอง ",
            "(ตัวอย่าง: โทเค็นหรือรหัส)",
            "ในการใช้เครื่องมือ myPassword เพื่อจัดการบัญชีองค์กรของคุณคุณ ",
            "ต้อง",
            " ลงทะเบียนวิธีการรับ 'ปัจจัย' ตัวที่สองเพื่อใช้ยืนยันตัวตนของคุณ."

        ],
        "vi": [
            "MFA là viết tắt của ",
            "Xác thực đa yếu tố",
            ". Xác thực đa yếu tố là một quy trình xác minh hai bước được sử dụng để xác nhận danh tính người dùng bằng cách sử dụng những thông tin mà người dùng biết ",
            "(ví dụ: mật khẩu)",
            ", cũng như một 'yếu tố' thứ hai ",
            "(ví dụ: mã thông báo hoặc mã)",
            "Để sử dụng công cụ myPassword để quản lý tài khoản doanh nghiệp của bạn, bạn ",
            "PHẢI",
            " đăng ký một phương thức để nhận 'yếu tố' thứ hai được sử dụng để xác nhận danh tính của bạn."
        ]

    },
    "howMFAQuestion":
    {
        "en": "How do I set up MFA for myPassword and other Liberty Mutual applications?",
        "es": "¿Cómo configuro MFA para myPassword y otras aplicaciones de Liberty Mutual?",
        "pt": "Como faço para configurar o MFA para myPassword e outros aplicativos da Liberty Mutual?",
        "th": "ฉันจะตั้งค่า MFA สำหรับ myPassword และแอปพลิเคชัน Liberty Mutual อื่น ๆ ได้อย่างไร",
        "vi": "Làm cách nào để thiết lập MFA cho myPassword và các ứng dụng Liberty Mutual khác?"

    },
    "howMFAAns":
    {
        "en": [
            "To setup or manage your ",
            "Self-Service Password Reset (SSPR)",
            " verification options (specific to myPassword), go to ",
            "https://aka.ms/ssprsetup",
            " and refer to ",
            "these instructions",
            "To setup or manage your ",
            "Multi-Factor Authentication/MFA",
            " settings (used by myPassword, Benefits Portal, Outlook Web Access and more), go to ",
            "https://aka.ms/mfasetup",
            " and refer to ",
            "these instructions."
        ],

        "es": [
            "Para configurar o administrar su ",
            "Self-Service Password Reset (SSPR)",
            " opciones de verificación (específicas de myPassword), vaya a ",
            "https://aka.ms/ssprsetup",
            " y siga ",
            "estas instrucciones",
            "Para configurar o administrar su ",
            "Autenticación multifactor / MFA",
            " ajustes (usados por myPassword, Benefits Portal, Outlook Web Access y más), ir a ",
            "https://aka.ms/mfasetup",
            " y siga ",
            "estas instrucciones."
        ],
        "pt": [
            "Para configurar ou gerir seu ",
            "Self-Service Password Reset (SSPR)",
            " e as opções de verificação (específicas do myPassword), vá a ",
            "https://aka.ms/ssprsetup",
            " e consulte ",
            "estas instruções",
            "Para configurar ou gerir seu ",
            "Autenticador Multifator / MFA",
            " e suas definições (usado por myPassword, Benefits Portal, Outlook Web Access e mais), vamos para ",
            "https://aka.ms/mfasetup",
            " e consulte ",
            " estas instruções"

        ],
        "th": [
            "เพื่อตั้งค่าหรือจัดการของคุณ ",
            "Self-Service Password Reset (SSPR)",
            " ตัวเลือกการยืนยัน (เฉพาะ myPassword) ไปที่ ",
            "https://aka.ms/ssprsetup",
            " และอ้างถึง ",
            "คำแนะนำเหล่านี้",
            "เพื่อตั้งค่าหรือจัดการของคุณ ",
            "การรับรองความถูกต้องแบบหลายปัจจัย / MFA",
            " การตั้งค่า (ใช้โดย myPassword, Benefits Portal, Outlook Web Access และอื่น ๆ), ไปที่ ",
            "https://aka.ms/mfasetup",
            " และอ้างถึง ",
            "คำแนะนำเหล่านี้"

        ],
        "vi": [
            "Để thiết lập hoặc quản lý ",
            "Self-Service Password Reset (SSPR)",
            " tùy chọn xác minh (cụ thể cho myPassword), đi đến ",
            "https://aka.ms/ssprsetup",
            " và tham khảo ",
            "những hướng dẫn này",
            "Để thiết lập hoặc quản lý ",
            "Xác thực đa yếu tố / MFA",
            " cài đặt (được sử dụng bởi myPassword, Benefits Portal, Outlook Web Access và hơn thế nữa), đi đến ",
            "https://aka.ms/mfasetup",
            " và tham khảo ",
            "những hướng dẫn này"
        ]

    },
    "forgotPWQuestion":
    {
        "en": "I didn't forget my password, I just want to change it. How do I do that?",
        "es": "No olvidé mi contraseña, solo quiero restablecerla. ¿Cómo puedo hacer eso?",
        "pt": "Não esqueci minha password, só quero redefini-la. Como faço?",
        "th": "ฉันไม่ได้ลืมรหัสผ่านฉันแค่ต้องการรีเซ็ต ฉันจะทำอย่างไร",
        "vi": "Tôi không quên mật khẩu, tôi chỉ muốn đặt lại. Làm thế nào để làm điều đó?"

    },
    "forgotPWAns":
    {
        "en": [
            "At this time, ",
            "even if you did not forget your password",
            ", you must select the 'I forgot my password' option in order to change your password.",
            "Currently the process to update your password, ",
            "whether you have forgotten it or not",
            ", is the same. As this experience is not customizable at this time, we are working with Microsoft to enhance the options currently available to be more clear and cause less confusion."
        ],
        "es": [
            "En este momento, ",
            "Incluso si no ha olvidado su contraseña",
            ", debe seleccionar la opción 'Olvidé mi contraseña' para cambiar su contraseña",
            "Actualmente el proceso para actualizar su contraseña, ",
            "si lo ha olvidado o no",
            ", es el mismo. Como esta experiencia no es personalizable en este momento, estamos trabajando con Microsoft para mejorar las opciones disponibles actualmente para que sean más claras y causen menos confusión."
        ],
        "pt": [
            "Nesse momento, ",
            "mesmo não se tendo esquecido da sua password",
            ", deve selecionar a opção 'Esqueci minha password' para a redefinir / alterar.",
            "Atualmente, o processo para atualizar sua password, ",
            "caso se tenha esquecido, ou não",
            ", é o mesmo. Como esta experiência não é, no momento, personalizável, estamos trabalhando com a Microsoft para aprimorar as opções disponíveis de modo a ficar mais claro e causar menos confusão."            
        ],
        "th": [
            "ณ ขณะนี้, ",
            "แม้ว่าคุณจะไม่ลืมรหัสผ่าน",
            ", คุณต้องเลือกตัวเลือก 'ฉันลืมรหัสผ่าน' เพื่อรีเซ็ต / เปลี่ยนรหัสผ่าน.",
            "ขณะนี้กระบวนการอัปเดตรหัสผ่านของคุณ, ",
            "ไม่ว่าคุณจะลืมหรือไม่",
            ", เหมือนกัน. เนื่องจากประสบการณ์นี้ไม่สามารถปรับแต่งได้ในขณะนี้เรากำลังทำงานร่วมกับ Microsoft เพื่อปรับปรุงตัวเลือกที่มีอยู่ในปัจจุบันให้ชัดเจนยิ่งขึ้นและทำให้เกิดความสับสนน้อยลง."

        ],
        "vi": [
            "Tại thời điểm này, ",
            "ngay cả khi bạn không quên mật khẩu của mình",
            ", bạn phải chọn tùy chọn 'Tôi quên mật khẩu của mình' để đặt lại / thay đổi mật khẩu của bạn.",
            "Hiện tại quá trình cập nhật mật khẩu của bạn, ",
            "cho dù bạn có quên nó hay không",
            ", là giống nhau. Vì trải nghiệm này không thể tùy chỉnh tại thời điểm này, chúng tôi đang làm việc với Microsoft để tăng cường các tùy chọn hiện có để rõ ràng hơn và ít gây nhầm lẫn hơn."            
        ]

    },
    "pwErrQuestion":
    {
        "en": "I keep receiving the error: 'This password does not meet the length, complexity, age, or history requirements of your corporate password policy.' What should I do?",
        "es": "Sigo recibiendo el error: 'Esta contraseña no cumple con los requisitos de longitud, complejidad, antigüedad o historial de su política de contraseñas corporativas'. ¿Qué tengo que hacer?",
        "pt": "Continuo recebendo o erro: 'A password não atende aos requisitos de tamanho, complexidade, idade ou histórico da sua política de password corporativa'. O que devo fazer?",
        "th":  "ฉันได้รับข้อผิดพลาด: 'รหัสผ่านนี้ไม่ตรงกับความยาวความซับซ้อนอายุหรือข้อกำหนดด้านประวัตินโยบายนโยบายรหัสผ่านขององค์กรของคุณ' ฉันควรทำอย่างไร?",
        "vi": "Tôi liên tục nhận được lỗi: 'Mật khẩu này không đáp ứng các yêu cầu về độ dài, độ phức tạp, độ tuổi hoặc lịch sử của chính sách mật khẩu công ty của bạn'. Tôi nên làm gì?"

    },
    "pwErrAns":
    {
        "en": [
            "You are receiving this error because you are attempting to set your password to one that is invalid per our ",
            "Enterprise Password Security Requirements",
            "You ",
            "MUST",
            " follow all requirements when selecting a new password in order to successfully update your password.",
            "Note:",
            " For security purposes, you can only view these requirements while connected to the Liberty Mutual network."
        ],
        "es": [
            "Está recibiendo este error porque está intentando establecer su contraseña en una que no es válida según nuestros ",
            "Requisitos de seguridad de contraseña de empresa",
            "Usted ",
            "DEBE",
            " seguir todos los requisitos cuando seleccione una nueva contraseña para actualizar su contraseña con éxito.",
            "Nota:",
            " Por motivos de seguridad, solo puede ver estos requisitos mientras está conectado a la red Liberty Mutual."
        ],
        "pt": [
            "Você está recebendo este erro porque está tentando definir sua password para uma que é inválida por nossa ",
            "Politica de segurança de password da empresa",
            "Você ",
            "DEVE",
            " siguir todos os requisitos ao selecionar uma nova password para atualizar sua password com sucesso.",
            "Nota:",
            " Por motivos de segurança, só pode ver estes requisitos quando está ligado à rede da Liberty Mutual."            
        ],
        "th": [
            "คุณได้รับข้อผิดพลาดนี้เนื่องจากคุณพยายามตั้งรหัสผ่านของคุณเป็นรหัสที่ไม่ถูกต้องสำหรับเรา ",
            "ข้อกำหนดด้านความปลอดภัยของรหัสผ่านองค์กร",
            "คุณ ",
            "ต้อง",
            " ปฏิบัติตามข้อกำหนดทั้งหมดเมื่อเลือกรหัสผ่านใหม่เพื่ออัปเดตรหัสผ่านของคุณให้สำเร็จ.",
            "บันทึก:",
            " เพื่อความปลอดภัยคุณสามารถดูข้อกำหนดเหล่านี้ได้ในขณะที่เชื่อมต่อกับเครือข่าย Liberty Mutual."
            
        ],
        "vi": [
            "Bạn đang nhận được lỗi này vì bạn đang cố gắng đặt mật khẩu của mình thành mật khẩu không hợp lệ theo chúng tôi ",
            "Yêu cầu bảo mật mật khẩu doanh nghiệp",
            "Bạn ",
            "PHẢI",
            " làm theo tất cả các yêu cầu khi chọn mật khẩu mới để cập nhật thành công mật khẩu của bạn.",
            "Chú thích:",
            " Vì mục đích bảo mật, bạn chỉ có thể xem các yêu cầu này trong khi kết nối với mạng Liberty Mutual."            
        ]

    },
    "aAccountQuestion":
    {
        "en": "Can I manage my A Account using myPassword?",
        "es": "¿Puedo administrar mi cuenta A usando myPassword?",
        "pt": "Posso gerir a minha conta usando myPassword?",
        "th": "ฉันสามารถจัดการบัญชี A โดยใช้ myPassword ได้ไหม?",
        "vi": "Tôi có thể quản lý Tài khoản A của mình bằng myPassword không?"

    },
    "aAccountAns":
    {
        "en": "No, currently myPassword can only be used to manage Enterprise N Accounts.",
        "es": "No, actualmente myPassword solo se puede utilizar para administrar cuentas de Empresa N.",
        "pt": "Não, atualmente o myPassword só pode ser usado para gerenciar contas corporative N (number).",
        "th": "ไม่ปัจจุบัน myPassword สามารถใช้เพื่อจัดการบัญชี N number เท่านั้น",
        "vi": "Không, hiện tại myPassword chỉ có thể được sử dụng để quản lý Tài khoản Enterprise N number."

    },
    "stillTroubleQuestion":
    {
        "en": "I'm still having trouble managing my enterprise account. What should I do?",
        "es": "Todavía tengo problemas para administrar mi cuenta de empresa. ¿Qué tengo que hacer?",
        "pt": "Ainda estou com problemas a gerir a minha conta corporativa. O que devo fazer?",
        "th": "ฉันยังคงมีปัญหาในการจัดการบัญชีองค์กรของฉัน ฉันควรทำอย่างไร?",
        "vi": "Tôi vẫn gặp sự cố khi quản lý tài khoản doanh nghiệp của mình. Tôi nên làm gì?"

    },
    "stillTroubleAns":
    {
        "en": [
            "For additional support, consult the ",
            "Help Yourself Desk",
            ", which includes how to get in touch with a Help Desk representative."
        ],
        "es": [
            "Para soporte adicional, consulte el ",
            "Help Yourself Desk",
            ", que incluye cómo ponerse en contacto con un representante de Help Desk."
        ],
        "pt": [
            "Para suporte adicional, consulte o ",
            "Help Yourself Desk",
            ", que inclui como entrar em contato com um representante do Help Desk."

        ],
        "th": [
            "สำหรับการสนับสนุนเพิ่มเติมปรึกษา ",
            "Help Yourself Desk",
            ", ซึ่งรวมถึงวิธีติดต่อกับตัวแทนฝ่ายช่วยเหลือ."
        ],
        "vi": [
            "Để được hỗ trợ thêm, tham khảo ý kiến ",
            "Help Yourself Desk",
            ", bao gồm cách liên lạc với đại diện của Bộ phận Trợ giúp."

        ]
    },
    "linkAccessQuestion":
    {
        "en": "I can't access some of the links on this page. Why?",
        "es": "No puedo acceder a algunos de los enlaces en esta página. ¿Por qué?",
        "pt": "Não consigo acessar alguns dos links nesta página. Por quê?",
        "th": "ฉันไม่สามารถเข้าถึงลิงก์บางส่วนในหน้านี้ ทำไม?",
        "vi": "Tôi không thể truy cập một số liên kết trên trang này. Tại sao?"

    },
    "linkAccessAns":
    {
        "en": "Some links on this page require you to be connected to the Liberty Mutual Network.",
        "es": "Algunos enlaces en esta página requieren que esté conectado a la red de Liberty Mutual.",
        "pt": "Alguns links nesta página exigem que você esteja conectado à rede da Liberty Mutual.",
        "th": "ลิงค์บางลิงค์ในหน้านี้ต้องการให้คุณเชื่อมต่อกับ Liberty Mutual Network.",
        "vi": "Một số liên kết trên trang này yêu cầu bạn phải kết nối với Mạng Liberty Mutual."

    },
    "mobilePWQuestion":
    {
        "en": "I'm having password issues with my iOS or Android device. What do I do?",
        "es": "Tengo problemas con la contraseña de mi dispositivo iOS o Android. ¿Qué debo hacer?",
        "pt": "Estou com problemas de password no meu dispositivo iOS ou Android. O que eu faço?",
        "th": "ฉันมีปัญหาเกี่ยวกับรหัสผ่านกับอุปกรณ์ iOS หรือ Android ฉันจะทำอย่างไร",
        "vi": "Tôi đang gặp vấn đề về mật khẩu với thiết bị iOS hoặc Android của mình. Tôi làm gì?"

    },
    "mobilePWAns":
    {
        "en": [
            "If you are having issues while trying to update your password for your mobile device, please follow ",
            "these instructions"
        ],
        "es": [
            "Si tiene problemas al intentar actualizar su contraseña para su dispositivo móvil, siga ",
            "estas instrucciones"
        ],
        "pt": [
            "Se você tiver problemas ao tentar atualizar sua password para seu dispositivo móvel, siga ",
            "estas instruções"

        ],
        "th": [
            "หากคุณประสบปัญหาขณะพยายามอัปเดตรหัสผ่านสำหรับอุปกรณ์มือถือของคุณโปรดติดตาม ",
            "คำแนะนำเหล่านี้"

        ],
        "vi": [
            "Nếu bạn gặp sự cố trong khi cố gắng cập nhật mật khẩu cho thiết bị di động của mình, vui lòng làm theo ",
            "những hướng dẫn này"

        ]

    },
    "mfaErrQuestion":
    {
        "en": "I have received the error 'We're sorry, you can't reset your password at this time because...' What should I do?",
        "es": "Recibí el error 'Lo sentimos, no puede restablecer su contraseña en este momento porque ...' ¿Qué debo hacer?",
        "pt": "Recebi o erro 'Desculpe, você não pode redefinir sua password neste momento porque ...' O que devo fazer",
        "th": "ฉันได้รับข้อผิดพลาด 'ขออภัยคุณไม่สามารถรีเซ็ตรหัสผ่านของคุณในขณะนี้เนื่องจาก ... ' ฉันควรทำอย่างไร",
        "vi": "Tôi đã nhận được lỗi 'Chúng tôi xin lỗi, bạn không thể đặt lại mật khẩu của mình tại thời điểm này vì ...' Tôi nên làm gì?"

    },
    "mfaErrAns":
    {
        "en": [
            "This means you have not yet registered your Self-Service Password Reset (SSPR) and/or Multi-factor Authentication (MFA) options which are required for you to be able to Self-Service Password Reset.",
            "If you are a new hire or have a temporary password, please use ",
            "Liberty Password Sync",
            " to initially change your password.",
            "Otherwise: ",
            "For more information regarding this error, please click ",
            "here",
            ],
        "es": [
            "Esto significa que aún no ha registrado su Self-Service Password Reset (SSPR) y / o las opciones de autenticación multifactor (MFA) que se requieren para que usted pueda usar Self-Service Password Reset.",
            "Si es un empleado nuevo o tiene una contraseña temporal, use ",
            "Liberty Password Sync",
            " para cambiar inicialmente su contraseña.",
            "De otra manera: ",
            "Para obtener más información sobre este error, haga clic ",
            "aquí",
            ],
        "pt": [
            "Isso significa que você ainda não registrou seu Self-Service Password Reset (SSPR) e / ou opções de Autenticação Multifator (MFA) que são necessárias para que você possa fazer um Self-Service Password Reset.",
            "Se você é um novo utilizador ou tem uma password temporária, por favor use ",
            "Liberty Password Sync",
            " para inicialmente alterar sua password.",
            "De outra forma: ",
            " Para mais informações sobre este erro, por favor clique ",
             "aqui" 
            ],
        "th": [
            "หมายความว่าคุณยังไม่ได้ลงทะเบียน Self-Service Password Reset (SSPR) และ / หรือตัวเลือกการรับรองความถูกต้องด้วยหลายปัจจัย (MFA) ซึ่งจำเป็นสำหรับคุณที่จะสามารถทำได้ Self-Service Password Reset.",
            "หากคุณเป็นผู้จ้างงานใหม่หรือมีรหัสผ่านชั่วคราวโปรดใช้ ",
            "Liberty Password Sync",
            " เพื่อเริ่มต้นเปลี่ยนรหัสผ่านของคุณ.",
            "มิฉะนั้น: ",
            "สำหรับข้อมูลเพิ่มเติมเกี่ยวกับข้อผิดพลาดนี้กรุณาคลิก  ",
            "ที่นี่"

            ],
        "vi": [
            "Điều này có nghĩa là bạn chưa đăng ký Self-Service Password Reset (SSPR) và / hoặc tùy chọn Xác thực đa yếu tố (MFA) được yêu cầu để bạn có thể Self-Service Password Reset.",
            "Nếu bạn là nhân viên mới hoặc có mật khẩu tạm thời, vui lòng sử dụng ",
            "Liberty Password Sync",
            " ban đầu thay đổi mật khẩu của bạn.",
            "Nếu không thì: ",
            "Để biết thêm thông tin về lỗi này, xin vui lòng bấm vào ",
            "đây"

             ],

    },

}
module.exports = FAQText;