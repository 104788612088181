let EntPwdExpiry1DayText = 
{
    "siteHeader":
    {
        "en": "Enterprise ID Password Expiration",
        "es": "Expiración de contraseña corporativa",
        "pt": "Expiração da Senha Corporativa",
        "th": "รหัสผ่านหมายเลข N หมดอายุ",
        "vi": "Thông báo hết hạn mật khẩu"

    },
    "subHeader":
    {
        "en": "For select individuals with a password approaching expiration ",
        "es": "Para individuos con claves prontas a expirar.",
        "pt": "Para pessoas com a expiração de senha próxima",
        "th": "สำหรับบุคคลที่เลือกด้วยรหัสผ่านที่ใกล้หมดอายุ",
        "vi": "Được gửi đến các cá nhân có mật khẩu đến hạn thay đổi"

    },
    "clickLang":
    {
        "en": "Click below to view this email in one of the following languages: ",
        "es": "Haga clic debajo para ver este correo en uno de los siguientes idiomas: ",
        "pt": "Clique em um dos links abaixo para ver este email em diferentes idiomas: ",
        "th": "Click below to view this email in one of the following languages: ",
        "vi": "Bấm vào dưới đây để xem email này bằng một trong các ngôn ngữ sau:"

    },
    "langs":
    {
        "en": ["Portuguese", "Spanish", "Thai", "Vietnamese"],
        "es": ["Português", "Español", "Thai", "Vietnamita"],
        "pt": ["Português", "Espanhol", "Thai", "Vietnamita"],
        "th": ["ชาวโปรตุเกส", "สเปน", "ไทย", "เวียตนาม"],
        "vi": ["Bồ Đào Nha", "Tây Ban Nha", "Thái Lan", "Việt Nam"]

    },
    "urgent":
    {
        "en": "**URGENT - IMMEDIATE ACTION REQUIRED**",
        "es": "**URGENTE – ACCIÓN REQUERIDA INMEDIATA **",
        "pt": "**URGENTE - REQUERIDA AÇÃO IMEDIATA**",
        "th": "** ด่วน - ดำเนินการในทันที **",
        "vi": "**KHẨN CẤP – BẠN PHẢI TIẾN HÀNH THAY ĐỔI MẬT KHẨU**"

    },
    "bodyText":
    {
        "en": 
        [
            "You are receiving this message because your Enterprise Password is due to expire",
            " tomorrow",
            ". This is the password associated with your N number and Liberty Mutual email address (firstname.lastname@libertymutual.com). You will notice a new and improved myPassword reset experience. We've simplified the process and made it more secure with Multi-Factor Authentication (MFA). Please see ",
            "this blog",
            " for additional information and to provide feedback.",
            "In order to avoid business interruption, please take a moment ",
            "now ",
            "to change your password via the redesigned ",
            "Please note:",
            " you will be asked for your \"User ID\" to reset your password; please enter your firstname.lastname@libertymutual.com email address for User ID, and not your N number."

        ],
        "es": 
        [
            "Usted está recibiendo este mensaje porque su contraseña corporativa expirará",
            " mañana",
            ". Esta es la contraseña asociada con su número N y la dirección de correo electrónico de Liberty Mutual (firstname.lastname@libertymutual.com). Notará una nueva y mejorada experiencia de restablecimiento de myPassword. Hemos simplificado el proceso y lo hemos hecho más seguro con la autenticación multifactor (MFA). Por favor mira ",
            "este blog",
            " para información adicional y para proporcionar comentarios.",
            "Con el fin de evitar interrupciones en su trabajo, por favor tómese un momento ",
            "ahora ",
            "y cambie su contraseña a través de, via ",
            "Tenga en cuenta",
            " que se le pedirá su \"ID de usuario\" para restablecer su contraseña; por favor ingrese su dirección de correo electrónico de Firstname.lastname@libertymutual.com para el ID de usuario, y no su número N. "

        ],
        "pt":
        [
            "Você está recebendo esta mensagem pois sua senha corporativa irá expirar",
            " amanhã",
            ". Esta é a senha associada ao seu número N e ao endereço de e-mail da Liberty Mutual (firstname.lastname@libertymutual.com). Você notará uma nova e aprimorada experiência de redefinição do myPassword. Nós simplificamos o processo e o tornamos mais seguro com a autenticação Multi-Factor (MFA). Por favor, veja ",
            "este blog",
            "  para obter informações adicionais e fornecer feedback.",
            "Para evitar interrupções de negócio, por gentileza, separe uns instantes para fazer a troca da senha",
            " agora ",
            ", via ",
            "Por favor, note:",
            " você será solicitado para você \"User ID\" para redefinir sua senha; por favor insira o seu endereço de e-mail firstname.lastname@libertymutual.com para o ID do usuário, e não o seu número N."

        ],
        "th":
        [
            "คุณได้รับข้อความนี้เนื่องจากรหัสผ่านของคุณ จะหมดอายุ",
            " วันพรุ่งนี้",
            ". นี่คือรหัสผ่านที่เชื่อมโยงกับหมายเลข N ของคุณและที่อยู่อีเมลของ Liberty Mutual (firstname.lastname@libertymutual.com) คุณจะสังเกตเห็นประสบการณ์การรีเซ็ต myPassword ใหม่ที่ได้รับการปรับปรุง เราได้ทำให้กระบวนการง่ายขึ้นและทำให้ปลอดภัยยิ่งขึ้นด้วยการรับรองความถูกต้องแบบหลายปัจจัย (MFA) ",
            "โปรดมอง บล็อกนี้",
            " สำหรับข้อมูลเพิ่มเติมและเพื่อให้ข้อเสนอแนะ",
            "พื่อหลีกเลี่ยงการใช้งานไม่ได้ โปรดใช้เวลาสักครู่ ",
            "ตอนนี้ ",
            "เปลี่ยนรหัสผ่านของคุณผ่านทาง ",
            " โปรดทราบ:",
            "  คุณจะถูกขอให้คุณ \"ID ผู้ใช้\" เพื่อรีเซ็ตรหัสผ่านของคุณ โปรดป้อนที่อยู่อีเมล firstname.lastname@libertymutual.com ของคุณสำหรับ ID ผู้ใช้ไม่ใช่หมายเลข N"

        ],
        "vi": 
        [
            "Bạn nhận được thông báo này do Mật khẩu của bạn sẽ hết hạn vào",
            " ngày mai",
            ". Đây là mật khẩu được liên kết với số N và địa chỉ email Liberty Mutual của bạn (Firstname.lastname@libertymutual.com). Bạn sẽ nhận thấy trải nghiệm thiết lập lại myPassword mới và được cải thiện. Chúng tôi đã đơn giản hóa quy trình và làm cho nó an toàn hơn với Xác thực đa yếu tố (MFA). Xin vui lòng ",
            "xem blog",
            " này để biết thêm thông tin và cung cấp thông tin phản hồi.",
            "Để tránh gián đoạn kinh doanh, hãy dành một chút thời gian ",
            "ngay bây giờ ",
            "để thay đổi mật khẩu bằng ",
            "Xin lưu ý:",
            "  bạn sẽ được yêu cầu \"ID người dùng\" để đặt lại mật khẩu; vui lòng nhập địa chỉ email Firstname.lastname@libertymutual.com cho ID người dùng chứ không phải số N của bạn."

        ]

    },


    "willExpire":
    {
        "en": ["Your password will expire in", "day(s)."],
        "es": ["Su Contraseña expirará en", "días."],
        "pt": ["Sua senha irá expirar em", "dia(s)."],
        "th": ["รหัสผ่านของคุณจะหมดอายุใน", "วัน."],
        "vi": ["Mật khẩu của bạn sẽ hết hạn sau", "ngày."],

    },
    "takeAction":
    {
        "en": "**PLEASE TAKE ACTION IMMEDIATELY**",
        "es": "**POR FAVOR TOME ACCIÓN INMEDIATAMENTE**",
        "pt": "**POR FAVOR, TOMAR AÇÃO IMEDIATAMENTE**",
        "th": "** โปรดดำเนินการทันที **",
        "vi": "**XIN VUI LÒNG THỰC HIỆN NGAY LẬP TỨC**"

    },
    "preventLock":
    {
        "en": ["ATTENTION", " To prevent from being temporarily \"locked out\" during the password reset process, make sure to first complete the following steps when prompted by myPassword:"],
        "es": ["ATENCIÓN", " Antes de restablecer su contraseña, primero debe completar los siguientes pasos:"],
        "pt": ["ATENÇÃO", " Antes de redefinir sua senha, você deve primeiro concluir as seguintes etapas:"],
        "th": ["สำคัญ:", " ก่อนทำการรีเซ็ตรหัสผ่าน คุณต้องทำสิ่งต่อไปนี้ให้สมบูรณ์ก่อน"],
        "vi": ["CHÚ Ý", " Trước khi thiết lập lại mật khẩu bạn phải hoàn tất các bước sau đây:"],

    },
    "steps":
    {
        "en": 
        [
            "Close ALL Liberty Mutual applications (e.g. Outlook, Skype for Business, Teams) and log out of ALL sessions (e.g. LibertyCLOUD, Citrix, Zephyr, Passport)",
            "Log out of any other Windows computers or virtual machines connected to the Liberty Network",
            "Log out of any mobile device applications that use your credentials (e.g. Outlook Web Access, Skype for Business, Teams)"

        ],
        "es":
        [
            "Cierra todo aplicaciones de Liberty Mutual (por ejemplo, Outlook, Skype for Business, Equipos) y cierre la sesión de TODAS las sesiones(por ejemplo, LibertyCLOUD, Citrix, Zephyr, Pasaporte)",
            "Cerrar sesión de cualquier otra computadora con Windows o máquinas virtuales conectadas a Liberty Network.",
            "Cerrar sesión de cualquier aplicación de dispositivo móvil que use sus credenciales (por ejemplo, Outlook Web Access, Skype Empresarial, Equipos"

        ],
        "pt":
        [
            "Feche tudo Aplicativos da Liberty Mutual (por exemplo, Outlook, Skype for Business, Teams) e logout de todas as sessões (por exemplo, LibertyCLOUD, Citrix, Zephyr, Passport)",
            "Sair de quaisquer outros computadores Windows ou máquinas virtuais conectados à Liberty Network",
            "Sair de qualquer aplicativo de dispositivo móvel que use suas credenciais (por exemplo, Outlook Web Access, Skype for Business, Teams)"

        ],
        "th": 
        [
            "ปิดแอปพลิเคชันทั้งหมด แอปพลิเคชันของ Liberty Mutual (เช่น Outlook, Skype for Business, ทีม) และออกจากระบบทั้งหมด (เช่น LibertyCLOUD, Citrix, Zephyr, Passport)",
            "ออกจากระบบ ของคอมพิวเตอร์ Windows เครื่องอื่นหรือเครื่องเสมือนที่เชื่อมต่อกับ Liberty Network",
            "ออกจากระบบ แอปพลิเคชันอุปกรณ์มือถือใด ๆ ที่ใช้ข้อมูลประจำตัวของคุณ (เช่น Outlook Web Access, Skype for Business, ทีม)"

        ],
        "vi": 
        [
            "Đóng tất cả \"Các ứng dụng Liberty Mutual\" (ví dụ: Outlook, Skype for Business, Teams) và đăng xuất khỏi TẤT CẢ các phiên (ví dụ: LibertyCLOUD, Citrix, Zephyr, Passport)",
            "Đăng xuất của bất kỳ máy tính Windows hoặc máy ảo nào khác được kết nối với Mạng Liberty",
            "Đăng xuất trong mọi ứng dụng thiết bị di động sử dụng thông tin đăng nhập của bạn (ví dụ: Outlook Web Access, Skype for Business, Teams)"

        ],

    },

}
module.exports = EntPwdExpiry1DayText;