import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FAQContent from '../instructions/FAQContent';
import '../../styles/application.css';
import ModalText from '../../text-content/ModalText';
import ButtonText from '../../text-content/ButtonText';
import { getLang } from '../../actions/langActions';

const styles = {};

class SimpleDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;
    let userLang = getLang();
    let faqTitle = ModalText.faqTitle[userLang];
    let close = ButtonText.close[userLang];
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
               <DialogTitle id="alert-dialog-title">
                <div className="modal-title">
                  <div><i className="fas fa-question-circle question-icon"></i></div>
                  <div className="modal-title-text">{faqTitle}</div>
                  <div><i className="fas fa-question-circle question-icon"></i></div>
               </div>
               </DialogTitle>
          <DialogContent>
            <DialogContentText component={'span'} id="alert-dialog-description">
              <FAQContent />
            </DialogContentText>
          </DialogContent>
          <DialogActions component={'span'}>
            <Button onClick={this.handleClose} color="primary" autoFocus>
             {close}
            </Button>
          </DialogActions>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class SimpleDialogDemo extends React.Component {
  state = {
    open: false,
    selectedValue: null,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    return (
      <div>
    <i className="far fa-question-circle fa-3x faq-icon" onClick={this.handleClickOpen}></i>
        <SimpleDialogWrapped
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default SimpleDialogDemo;