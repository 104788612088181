let NewEntPwdExpiryDayText = 
{
    "toEmployees":
    {
        "en": "To: employees and contractors with passwords about to expire",
        "es": "Para: empleados y contratistas con contraseñas a punto de caducar",
        "pt": "Para: funcionários e contratados com passwords prestes a expirar",
        "th": "ถึง: พนักงานและผู้รับเหมาที่มีรหัสผ่านกำลังจะหมดอายุ",
        "vi": "Kính Gửi: nhân viên và nhân viên hợp đồng có mật khẩu sắp hết hạn"

    },
    "aMessage":
    {
        "en": ["A message from", "The IT Help Desk"],
        "es": ["Un mensaje de", "La IT Help Desk"],
        "pt": ["Uma mensagem de", "O IT Help Desk"],
        "th": ["ข้อความจาก", "IT Help Desk"],
        "vi": ["Một tin nhắn từ", "Các IT Help Desk"]

    },
    "pwTitle1Day":
    {
        "en": "Your password expires tomorrow! Don’t get locked out – change it now.",
        "es": "¡Su contraseña caduca mañana! No se quede bloqueado, cámbiela ahora.",
        "pt": "Sua password está prestes a expirar! Mude hoje.",
        "th": "รหัสผ่านของคุณจะหมดอายุในวันพรุ่งนี้! เพื่อไม่ถูกล็อค - เปลี่ยนทันที",
        "vi": "Mật khẩu của bạn sẽ hết hạn vào ngày mai! Đừng để bị khóa - thay đổi ngay bây giờ."
    },
    "pwTitleAlmostExpired":
    {
        "en": "Your password is about to expire! Change it today.",
        "es": "¡Su contraseña está a punto de caducar! Cámbiela hoy.",
        "pt": "Sua password expira amanhã! Não fique bloqueado - mude agora.",
        "th": "รหัสผ่านของคุณใกล้จะหมดอายุแล้ว! เปลี่ยนวันนี้",
        "vi": "Mật khẩu của bạn sắp hết hạn! Thay đổi nó ngay hôm nay."

    },
    "willExpire":
    {
        "en": ["Your password is ", "due to expire in ", " days."],
        "es": ["Su contraseña es ", "que expira en ", " días."],
        "pt": ["Sua password é ", "que expira em ", " dias."],
        "th": ["รหัสผ่านของคุณคือ ", "เนื่องจากจะหมดอายุใน ", " วัน"],
        "vi": ["Mật khẩu của bạn là ", "do hết hạn trong ", " ngày"]

    },
    "instructions":
    {
        "en": ["Go to ", " to change the password associated with your Enterprise ID (n-number and Liberty Mutual email address)."],
        "es": ["Vaya a ", " para cambiar la contraseña asociada con su ID de empresa (n-number y la dirección de correo electrónico de Liberty Mutual)."],
        "pt": ["Vamos para ", " para alterar a password associada ao seu Enterprise ID (n-number e endereço de e-mail da Liberty Mutual)."],
        "th": ["ไปที่ ", " เพื่อเปลี่ยนรหัสผ่านที่เชื่อมโยงกับ Enterprise ID ของคุณ (ที่อยู่อีเมลและหมายเลข Liberty Mutual)"],
        "vi": ["Đi đến ", " để thay đổi mật khẩu được liên kết với ID doanh nghiệp của bạn (số n và địa chỉ email Liberty Mutual)."]

    },
    "pillars":
    {
        "en": ["Periodically updating passwords (and never sharing them) is one of our ", "Pillars of Data Protection", " to keep Liberty’s information and systems secure." ],
        "es": ["Actualizar periódicamente las contraseñas (y nunca compartirlas) es uno de nuestros ", "Pilares de la protección de datos", " para mantener la información y los sistemas de Liberty seguros." ],
        "pt": ["Atualizar periodicamente as senhas (e nunca compartilhá-las) é um dos nossos ", "Pilares de Proteção de Dados", " para manter as informações e os sistemas da Liberty seguros." ],
        "th": ["การอัปเดตรหัสผ่านเป็นระยะ (และไม่แชร์รหัสผ่าน) เป็นหนึ่งในของเรา ", "เสาหลักของการป้องกันข้อมูล", " เพื่อรักษาข้อมูลและระบบของ Liberty ให้ปลอดภัย" ],
        "vi": ["Cập nhật mật khẩu định kỳ (và không bao giờ chia sẻ chúng) là một trong những ", "Trụ cột bảo vệ dữ liệu", " để giữ an toàn cho thông tin và hệ thống của Liberty." ],
    },
    "responsibleDefender":
    {
        "en": "Thank you for being a Responsible Defender!",
        "es": "¡Gracias por ser un Defensor Responsable!",
        "pt": "Obrigado por ser um Defensor Responsável!",
        "th": "ขอบคุณสำหรับการเป็นผู้พิทักษ์ที่มีความรับผิดชอบ!",
        "vi": "Cảm ơn bạn đã là một người bảo vệ có trách nhiệm!"

    },
    "clickFor":
    {
        "en": "Click below to view this email in one of the following languages: ",
        "es": "Haga clic a continuación para ver este correo electrónico en uno de los siguientes idiomas: ",
        "pt": "Clique abaixo para ver este e-mail em um dos seguintes idiomas: ",
        "th": "คลิกด้านล่างเพื่อดูอีเมลนี้ในหนึ่งในภาษาต่อไปนี้: ",
        "vi": "Nhấp vào bên dưới để xem email này bằng một trong các ngôn ngữ sau: "

    },
    "langs":
    {
        "en": ["Portuguese", "Spanish", "Thai", "Vietnamese"],
        "es": ["Portugués", "Español", "Thai", "Vietnamita"],
        "pt": ["Português", "Espanhol", "Thai", "Vietnamita"],
        "th": ["โปรตุเกส", "สเปน", "ไทย", "เวียตนาม"],
        "vi": ["Bồ Đào Nha", "Tây Ban Nha", "Thái Lan", "Việt Nam"]

    }

}
module.exports = NewEntPwdExpiryDayText;