import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../../styles/application.css';
import Links from '../../text-content/Links';
import FAQText from '../../text-content/FAQText';
import { getLang } from '../../actions/langActions';
import ModalText from '../../text-content/ModalText';
const styles = theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '400',
    color: '#1a1446'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  title: {
    background: '#ffd000',
    border: '2px solid transparent',
    borderRadius: '5px',
    marginBottom: '2px'

  },
  details: {
    textAlign: 'center',
    display: 'block',
    width:'100%',
  },
  column: {
    width: '50%',
    paddingLeft:'2%',
    paddingRight:'2%'
  },
  colLine: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    textAlign: 'center'
  },
  summary: {
    boxShadow: 'none',
    border: '0px transparent solid',
    marginRight: '10px',
    background: '#FFFFFF'
  }
});

class FAQContent extends React.Component {
  state = {
    expanded: null,
  };

  componentDidMount(){
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    let userLang = getLang();
    let employeeTypeList = ModalText.employeeTypeList[userLang];
    let lpsText = ModalText.lpsText[userLang];
    let countryList = ModalText.countryList[userLang];
    let lpsQuestion = FAQText.lpsQuestion[userLang];
    let lpsAnswer = FAQText.lpsAnswer[userLang];
    let whyMFAQuestion = FAQText.whyMFAQuestion[userLang];
    let whyMFAAns = FAQText.whyMFAAns[userLang];
    let howMFAQuestion = FAQText.howMFAQuestion[userLang];
    let howMFAAns = FAQText.howMFAAns[userLang];
    let forgotPWQuestion = FAQText.forgotPWQuestion[userLang];
    let forgotPWAns = FAQText.forgotPWAns[userLang];
    let pwErrQuestion = FAQText.pwErrQuestion[userLang];
    let pwErrAns = FAQText.pwErrAns[userLang];
    let aAccountQuestion = FAQText.aAccountQuestion[userLang];
    let aAccountAns = FAQText.aAccountAns[userLang];
    let stillTroubleQuestion = FAQText.stillTroubleQuestion[userLang];
    let stillTroubleAns = FAQText.stillTroubleAns[userLang];
    let linkAccessQuestion = FAQText.linkAccessQuestion[userLang];
    let linkAccessAns = FAQText.linkAccessAns[userLang];
    let mobilePWQuestion = FAQText.mobilePWQuestion[userLang];
    let mobilePWAns = FAQText.mobilePWAns[userLang];
    let mfaErrQuestion = FAQText.mfaErrQuestion[userLang];
    let mfaErrAns = FAQText.mfaErrAns[userLang];
    let ssprMFAInstr = Links.ssprMFAInstr;
    let lmMFAInstr = Links.lmMFAInstr;
    let ssprMFASetup = Links.ssprMFASetup;
    let lmMFASetup = Links.lmMFASetup;
    let enterprisePWreq = Links.enterprisePWreq;
    let mobileInstructions = Links.mobileInstructions;
    let helpYourselfDesk = Links.helpYourselfDesk;
    let mfaErrLink = Links.mfaErrLink;

    return (
      <div className={`${classes.root} modal-scroll`}>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panelx'} onChange={this.handleChange('panelx')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{lpsQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'} className={classes.root}> 
          <p>{employeeTypeList[0]}<strong>{employeeTypeList[1]}</strong>{employeeTypeList[2]}<strong>{employeeTypeList[3]}</strong>{employeeTypeList[4]}<strong>{employeeTypeList[5]}</strong>{employeeTypeList[6]}</p>
              <div className='row countries-modal'>
              <ul className='countries-list'>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/bermuda.png')} alt ='bermuda-flag'/> {countryList[11]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/brazil.png')} alt ='brazil-flag'/> {countryList[0]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/chile.png')} alt ='chile-flag' /> {countryList[1]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/china.png')} alt ='china-flag' /> {countryList[2]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/colombia.png')} alt ='colombia-flag' /> {countryList[3]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/ecuador.png')} alt ='ecuador-flag' /> {countryList[4]}</li>
              </ul>
              <ul className='countries-list'>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/ireland.png')} alt ='ireland-flag' /> {countryList[5]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/malaysia.png')} alt ='malaysia-flag' /> {countryList[6]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/portugal.png')} alt ='portugal-flag' /> {countryList[7]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/russia.png')} alt ='russia-flag' /> {countryList[8]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/spain.png')} alt ='spain-flag' /> {countryList[9]}</li>
                  <li className="country-pair"><img className="country-flag" src={require('../../assets/images/venezuela.png')} alt ='venezuela-flag' /> {countryList[10]}</li>
              </ul>
              </div>
              <p>{lpsText[0]}<a href={Links.lps}>{lpsText[1]}</a>{lpsText[2]}</p>
              <p>{lpsAnswer[0]}<a href='/'>{lpsAnswer[1]}</a>{lpsAnswer[2]}</p>
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  className={classes.summary} expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')} defaultExpanded>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{whyMFAQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={'span'}>
            {whyMFAAns[0]}<strong>{whyMFAAns[1]}</strong>{whyMFAAns[2]}<em>{whyMFAAns[3]}</em>{whyMFAAns[4]}<em>{whyMFAAns[5]}</em>.
            <br /><br />
            {whyMFAAns[6]}<strong>{whyMFAAns[7]}</strong>{whyMFAAns[8]}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{howMFAQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={'span'}>
                <ul>
                    <li>{howMFAAns[0]}<strong>{howMFAAns[1]}</strong>{howMFAAns[2]}<a href={ssprMFASetup} target="_blank" rel="noopener noreferrer">{howMFAAns[3]}</a>{howMFAAns[4]}<a href={ssprMFAInstr} target="_blank" rel="noopener noreferrer">{howMFAAns[5]}</a></li>
                    <li>{howMFAAns[6]}<strong>{howMFAAns[7]}</strong>{howMFAAns[8]}<a href={lmMFASetup} target="_blank" rel="noopener noreferrer">{howMFAAns[9]}</a>{howMFAAns[10]}<a href={lmMFAInstr} target="_blank" rel="noopener noreferrer">{howMFAAns[11]}</a></li>
                </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{forgotPWQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'}> 
          {forgotPWAns[0]}<strong>{forgotPWAns[1]}</strong>{forgotPWAns[2]}
        <br /><br />
          {forgotPWAns[3]}<strong>{forgotPWAns[4]}</strong>{forgotPWAns[5]}
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel4'} onChange={this.handleChange('panel4')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{mfaErrQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={'span'}>
                    {mfaErrAns[0]}
                    <br /><br />
                    {mfaErrAns[1]}<a href={Links.lps}>{mfaErrAns[2]}</a>{mfaErrAns[3]}
                    <br /><br />
                    {mfaErrAns[4]}
                    <ul>
                    <li>{howMFAAns[0]}<strong>{howMFAAns[1]}</strong>{howMFAAns[2]}<a href={ssprMFASetup} target="_blank" rel="noopener noreferrer">{howMFAAns[3]}</a>{howMFAAns[4]}<a href={ssprMFAInstr} target="_blank" rel="noopener noreferrer">{howMFAAns[5]}</a></li>
                    <li>{howMFAAns[6]}<strong>{howMFAAns[7]}</strong>{howMFAAns[8]}<a href={lmMFASetup} target="_blank" rel="noopener noreferrer">{howMFAAns[9]}</a>{howMFAAns[10]}<a href={lmMFAInstr} target="_blank" rel="noopener noreferrer">{howMFAAns[11]}</a></li>
                </ul>
       
                {mfaErrAns[5]}<a href={mfaErrLink} target="_blank" rel="noopener noreferrer">{mfaErrAns[6]}</a>.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel5'} onChange={this.handleChange('panel5')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{pwErrQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'}> 
            {pwErrAns[0]}<a href={enterprisePWreq} target="_blank" rel="noopener noreferrer">{pwErrAns[1]}</a>.
            <br />
            {pwErrAns[2]}<strong>{pwErrAns[3]}</strong>{pwErrAns[4]}
            <br /><br />
            <strong>{pwErrAns[5]}</strong>{pwErrAns[6]}
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel6'} onChange={this.handleChange('panel6')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{aAccountQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'}> 
            {aAccountAns}
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel7'} onChange={this.handleChange('panel7')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{mobilePWQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'}> 
            {mobilePWAns[0]}<a href={mobileInstructions} target="_blank" rel="noopener noreferrer">{mobilePWAns[1]}</a>
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel8'} onChange={this.handleChange('panel8')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{linkAccessQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'}> 
            {linkAccessAns}
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.summary} expanded={expanded === 'panel9'} onChange={this.handleChange('panel9')}>
          <ExpansionPanelSummary className={`${classes.title} ${"faq-question-panel"}`} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{stillTroubleQuestion}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'} className={classes.root}> 
          {stillTroubleAns[0]}<a href={helpYourselfDesk} target="_blank" rel="noopener noreferrer">{stillTroubleAns[1]}</a>{stillTroubleAns[2]}
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FAQContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQContent);