let HeaderText = 
{
    "subHeader":
    {
        "en": "Please review instructions below, as they may have changed since you last managed your enterprise account.",
        "es": "Revise las instrucciones a continuación, ya que pueden haber cambiado desde la última vez que administró su cuenta de empresa.",
        "pt": "Analise as instruções abaixo, pois elas podem ter mudado desde a última vez que você geriu sua conta corporativa.",
        "th": "โปรดอ่านคำแนะนำด้านล่างเนื่องจากอาจมีการเปลี่ยนแปลงนับตั้งแต่คุณจัดการบัญชีองค์กรของคุณครั้งล่าสุด",
        "vi": "Vui lòng xem lại hướng dẫn bên dưới, vì chúng có thể đã thay đổi kể từ lần cuối bạn quản lý tài khoản doanh nghiệp của mình."

    }
}
module.exports = HeaderText;