let ButtonText = 
{
    "managePW":
    {
        "en": "Manage Password",
        "es": "Administrar contraseña",
        "pt": "Gerir Password",
        "th": "จัดการรหัสผ่าน",
        "vi": "Quản lý mật khẩu"

    },
    "unlockAcc":
    {
        "en": "Unlock Account",
        "es": "Desbloquear cuenta",
        "pt": "Desbloquear conta",
        "th": "ปลดล็อคบัญชี",
        "vi": "Mở khoá tài khoản"

    },
    "yesContinue":
    {
        "en": "Yes, Continue",
        "es": "Si, continúar",
        "pt": "Sim, continuar",
        "th": "ใช่ดำเนินการต่อ",
        "vi": "Vâng, tiếp tục"

    },
    "goBack":
    {
        "en": "No, Go Back",
        "es": "No, regresar",
        "pt": "Não, regressar",
        "th": "ไม่, ย้อนกลับ",
        "vi": "Không, quay lại"

    },
    "continueToPW":
    {
        "en": "No, continue to myPassword",
        "es": "No, continúar con myPassword",
        "pt": "Não, continuar para myPassword",
        "th": "ไม่, ดำเนินการต่อไปยัง myPassword",
        "vi": "Không, tiếp tục với myPassword"

    },
    "close":
    {
        "en": "Close",
        "es": "Cerrar",
        "pt": "Fechar",
        "th": "ปิด",
        "vi": "Đóng"

    },
}
module.exports = ButtonText;