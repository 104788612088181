import React, { Component } from 'react';
import '../../styles/application.css';
import UnlockText from '../../text-content/UnlockText';
import UnlockInstructions from '../instructions/UnlockInstructions';
import AppButton from '../buttons/AppButton';
import { getLang } from '../../actions/langActions';
import ButtonText from '../../text-content/ButtonText';
import Links from '../../text-content/Links';

class UnlockCard extends Component {
  constructor(props){
    super(props);
    this.state = {panel: null};
    this.handleCloseContinue = this.handleCloseContinue.bind(this);
  }

  handleCloseContinue () {
    this.setState({panel: 'panel1' });
  };

render(){
  let userLang = getLang();
  let subTitle = UnlockText.subTitle[userLang];
  let title = UnlockText.title[userLang];
  let unlockAcc = ButtonText.unlockAcc[userLang];
  let microsoftAccManagement = Links.microsoftAccManagement;
        return (
          <div className="card-body">
          <div>
            <h2 className="card-header">{title}</h2>
            <div className="card-subheader">
              <i className="card-subheader"><small>{subTitle}</small></i>
            </div>
            <hr/>
            <UnlockInstructions expanded={this.state.panel}/>
          </div>
          <div className='button-wrapper'>
            <a href={microsoftAccManagement} onClick={this.handleCloseContinue} target="_blank" rel="noopener noreferrer" id="unlock-button"><AppButton title={unlockAcc} /></a>
          </div>
        </div>
        );
      };
    };
    
export default UnlockCard;