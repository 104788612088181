import React, { Component } from 'react';
import '../../styles/application.css';
import FAQModal from '../modals/FAQModal';

class Header extends Component {

render(){
        return (
          <div id="section-header">
            <div className="logo-item"> 
              <img id="logo-img" src={require('../../assets/images/LMLogo.png')} alt='lm-logo'/>
              <img id="logo-img-small" src={require('../../assets/images/LMMedallion.png')} alt='lm-logo-small'/>
            </div> 
              <div className="header-item"><h1 id="app-title">myPassword</h1></div>
              <div className="header-item"><FAQModal /></div>
          </div>
        );
      };
    };
    
export default Header;