let Links = 
{
    "lps": "https://lps.lmig.com/",
    "enterprisePWreq": "https://libertymutual.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=040964100010832&page=1&position=5&q=",
    "macInstructions": "https://libertymutual-ss.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=160229122025506",
    "helpYourselfDesk": "https://libertymutual-ss.rightanswers.com/portal/ss/index.jsp",
    "mobileInstructions": "https://libertymutual-ss.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=110324173054498",
    "ssprMFAInstr": "https://libertymutual-ss.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=190326230459530",
    "lmMFAInstr": "https://libertymutual-ss.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=180820170312468",
    "ssprMFASetup": "https://aka.ms/ssprsetup",
    "lmMFASetup": "https://aka.ms/mfasetup",
    "microsoftAccManagement": "https://passwordreset.microsoftonline.com/?whr=libertymutual.com",
    "mfaErrLink": "https://libertymutual-ss.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=190326130538807"

}
module.exports = Links;