let EntPwdExpiry1DayText = 
{
    "additionalBodyTextReplacement":
    {
        "en": "At your earliest convenience, please take a moment to change your password via the redesigned ",
        "es": "Con el fin de evitar interrupciones en su trabajo, por favor tómese un momento ahora y cambie su contraseña a través de ",
        "pt": "Para sua conveniência, por gentileza, separe alguns instantes para alterar sua senha via ",
        "th": "ณ เวลาที่คุณสะดวกที่สุด, โปรดใช้เวลาสักครู่เพื่อเปลี่ยนรหัสผ่านของคุณผ่านทาง ",
        "vi": "Để tránh gián đoạn trong công việc, hãy dành một chút thời gian ngay bây giờ để thay đổi mật khẩu bằng bằng "

    }
}
module.exports = EntPwdExpiry1DayText;