import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UnlockText from '../../text-content/UnlockText';
import { getLang } from '../../actions/langActions';

const styles = theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '300',
    color: '#1a1446'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  title: {
    background: '#78E1E1'
  },
  details: {
    textAlign: 'center',
    display: 'block',
    width:'100%',
    color: '#1a1446'
  },
  column: {
    width: '50%',
    paddingLeft:'2%',
    paddingRight:'2%'
  },
  colLine: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    textAlign: 'center'
  }
});

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null,
  };
  componentWillReceiveProps(){
    this.setState({
      expanded: 'panel1'
  })
  }
  componentDidMount(){
    this.setState({
        expanded: this.props.expanded
    })
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    let userLang = getLang();
    let instrHeader = UnlockText.instrHeader[userLang];
    let instructions = UnlockText.instructions[userLang];
    let clickButtonInstr = UnlockText.clickButtonInstr[userLang];
    return (
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')} >
          <ExpansionPanelSummary className={classes.title} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{instrHeader}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={'span'}>
            <p>{clickButtonInstr}</p>
            <ol>
              <li>{instructions[0]}<strong>{instructions[1]}</strong>{instructions[2]}<strong>{instructions[3]}</strong>.</li>
              <li>{instructions[4]}<strong>{instructions[5]}</strong>.</li>
              <li>{instructions[6]}<strong>{instructions[7]}</strong>.</li>
              <li>{instructions[8]}</li>
            </ol>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);