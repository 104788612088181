import React, { Component } from 'react';
import '../../styles/css/notification.css';
import queryString from 'query-string';
import EntPwdExpiry1DayText from '../../text-content/email-notifications/EntPwdExpiry1DayText';
import EntPwdExpiry2_3DaysText from '../../text-content/email-notifications/EntPwdExpiry2_3DaysText';
import EntPwdExpiry5_21DaysText from '../../text-content/email-notifications/EntPwdExpiry5_21DaysText';

const divHeaderStyle = {
    backgroundColor:'white', 
    width:'600px'
  }
  
  const divBodyStyle = {
    borderTop: '2px dotted #cccccc',
    borderBottom: '2px dotted #cccccc'
  }
  const pwText={
    fontSize: '11pt'
  }
  const employeeText = {
    color: '#888'
  }
  const importantText = {
    color: '#FF0000',
    fontWeight: 'bold'
  }

class EntPwdExpiry5_21Days extends Component {
  constructor(props){
    super(props);
    this.state = {
        days: 'X',
        currLang: this.props.language
      };
  }
  componentDidMount() {
    const { location: { search } } = this.props;
    const values = queryString.parse(search);
    if (!values.days || values.days > 21 || values.days === null || values.days === undefined){
      this.setState({days: 'X'})
    }
    else if (values.days <= 21 && values.days % 1 === 0){
      this.setState({days: values.days})
    }
  }
  handleClick = language => e => {
    e.preventDefault()
    this.setState({currLang: language})
  }
render(){
  let userLang = this.state.currLang
  let siteHeader= EntPwdExpiry1DayText.siteHeader[userLang]
  let subHeader= EntPwdExpiry1DayText.subHeader[userLang]
  let clickLang= EntPwdExpiry1DayText.clickLang[userLang]
  let langs= EntPwdExpiry1DayText.langs[userLang]
  let bodyText= EntPwdExpiry1DayText.bodyText[userLang]
  let willExpire= EntPwdExpiry1DayText.willExpire[userLang]
  let preventLock= EntPwdExpiry1DayText.preventLock[userLang]
  let steps= EntPwdExpiry1DayText.steps[userLang]

  let ignore= EntPwdExpiry2_3DaysText.ignore[userLang]
  let additionalBodyText= EntPwdExpiry2_3DaysText.additionalBodyText[userLang]
  let safecoTitle= EntPwdExpiry2_3DaysText.safecoTitle[userLang]
  let safecoSteps= EntPwdExpiry2_3DaysText.safecoSteps[userLang]
  let safecoSupportTitle= EntPwdExpiry2_3DaysText.safecoSupportTitle[userLang]
  let safecoContact= EntPwdExpiry2_3DaysText.safecoContact[userLang]
  let functionalityAvail= EntPwdExpiry2_3DaysText.functionalityAvail[userLang]

  let additionalBodyTextReplacement = EntPwdExpiry5_21DaysText.additionalBodyTextReplacement[userLang]
  let solutionLink = 'https://libertymutual-ss.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=090803143311500'
  if(userLang === 'vi'){
    solutionLink = 'https://outside.safeco.com/outside/pwchange/pwchange.asp'
  }
        return (
        <center>
            <div style={divHeaderStyle}>
                <img src={require('./../../assets/email-notification/img/myPW_header.jpg')} width="600px" alt="lm-banner" />  
                <table border="0" cellPadding="3" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>
                                <div className="emailcontainer" >
                                    <br />
                                    <span className="header2C" >{siteHeader}</span>
                                    <br />
                                    <span className="italicsmall">{subHeader}</span>
                                    <br />
                                    <hr />
                                    <span className="bodytext">{clickLang}</span>
                                    <br /><br />
                                    <img src={require('./../../assets/email-notification/img/BR_Flag.gif')} alt="brazil-flag" />&nbsp;
                                    <img src={require('./../../assets/email-notification/img/PT_Flag.gif')} alt="portugal-flag" />&nbsp;
                                    <a href="/" onClick={this.handleClick('pt')}><span className="translatedlinks">{langs[0]}</span></a>&nbsp; &nbsp; &nbsp; &nbsp;
                                    <img src={require('./../../assets/email-notification/img/CL_Flag.gif')} alt="colombia-flag" />&nbsp;
                                    <img src={require('./../../assets/email-notification/img/EC_Flag.gif')} alt="ecudador-flag" />&nbsp;
                                    <img src={require('./../../assets/email-notification/img/ES_Flag.gif')} alt="spain-flag" />&nbsp;
                                    <img src={require('./../../assets/email-notification/img/VZ_Flag.gif')} alt="venezuela-flag" />&nbsp;
                                    <a href="/" onClick={this.handleClick('es')}><span className="translatedlinks">{langs[1]}</span></a>&nbsp; &nbsp; &nbsp; &nbsp;
                                    <img src={require('./../../assets/email-notification/img/TH_Flag.gif')} alt="thai-flag" />&nbsp;
                                    <a href="/" onClick={this.handleClick('th')}><span className="translatedlinks">{langs[2]}</span></a>&nbsp; &nbsp; &nbsp; &nbsp;
                                    <img src={require('./../../assets/email-notification/img/VN_Flag.gif')} alt="vietnam-flag" />&nbsp;
                                    <a href="/" onClick={this.handleClick('vi')}><span className="translatedlinks">{langs[3]}</span></a>&nbsp; &nbsp; &nbsp; &nbsp;
                                    <hr />
                                    <span className="bodytext">{bodyText[0]}{bodyText[2]}<a href="https://myconnections.lmig.com/groups/gis-identity-access-management/blog/2019/03/13/introducing-mypassword-a-new-more-secure-reset-experience">{bodyText[3]}</a>{bodyText[4]}</span>
                                    <br /><br />
                                    <div style={divBodyStyle}>
                                        <table width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="bodytext" style={pwText}>{willExpire[0]}<strong> <span id="days">{this.state.days}</span> </strong>{willExpire[1]}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <span className="bodytext">{additionalBodyTextReplacement}<a href="https://mypassword.lmig.com/"><span><strong>myPassword</strong></span></a>. <strong>{bodyText[8]}</strong>{bodyText[9]}</span>
                                    <br /><br />
                                    <span className="datared"><strong>{preventLock[0]}</strong>{preventLock[1]}</span>	
                                    <span className="bodytext">	
                                        <ol>	
                                            <li>{steps[0]}</li>	
                                            <li>{steps[1]}</li>	
                                            <li>{steps[2]}</li>
                                        </ol>	
                                    </span>	
                                    <span className="italic">{ignore}</span>
                                    <br /><br />
                                    <span className="bodytext">{additionalBodyText[0]}</span>
                                    <br /><br />
                                    <span className="boldtext">{additionalBodyText[1]}</span><span className="bodytext">{additionalBodyText[2]}</span>
                                    <br /><br />
                                    <span className="header2C">{safecoTitle}</span>
                                    <br />
                                    <span className="italicsmall">{safecoSteps[0]}</span>
                                    <br /><br />
                                    <span className="bodytext">{safecoSteps[1]}<a href="https://mypassword.lmig.com/"><span><strong>myPassword</strong></span></a>{safecoSteps[2]}</span>
                                    <br /><br />
                                    <span className="bodytext">{safecoSteps[3]}<a href={solutionLink}>{safecoSteps[4]}</a><span style={importantText}>&#42;</span>{safecoSteps[5]}</span>
                                    <br /><br />
                                    <span className="bodytext">{safecoSteps[6]}</span>
                                    <br /><br />
                                    <span className="header2C">{safecoSupportTitle}</span>
                                    <br /><br />
                                    <span className="bodytext">{safecoContact[0]}<a href="http://help.lmig.com/">{safecoContact[1]}</a><span className="datared">*</span>{safecoContact[2]}<span><em>myPassword</em></span>{safecoContact[3]}</span>
                                    <br /><br />
                                    <span className="bodytext" style={importantText}>&#42;</span><em className="bodytext" style={employeeText}>{functionalityAvail}</em>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <img src={require('./../../assets/email-notification/img/FOOTER_CorpBrand.png')} width="600px" alt="lm-footer" />  
            </div> 
        </center>
        );
      };
    };
    
export default EntPwdExpiry5_21Days;