import React, { Component } from 'react';
import Header from '../headers/Header';


const sorryDiv = {
    border: '3px solid #FF0000',
    borderRadius: '20px',
    padding: '1em',
    wordWrap: 'break-word',
    background: '#FFFFFF'
}
const pageFormat = {
    padding: ' 0 15% 0 15%'
}
const headerSpacing = {
    margin: '30px 0 20px 0',
    fontWeight: '100'
}
const redirectFont = {
    fontFamily: 'Guardian Sans, sans-serif'
}
class CredRedirect extends Component {

render(){
        return (
            <center>
            <div style={redirectFont}>
                <Header />
                <h2 style={headerSpacing}>Password Management Instructions</h2>
                <div style={pageFormat}>
                <div style={sorryDiv}>
                    <div>We're sorry the page you tried to navigate to no longer exists. For information on how to reset your password or unlock your account, please access the following site:</div>
                    <br />
                    <h2><strong>https://mypassword.lmig.com/</strong></h2>    
                </div>
                </div>
            </div>
            </center>
        );
      };
    };
    
export default CredRedirect;