let ModalText = 
{
    "onLoadTitle":
    {
        "en": "ATTENTION",
        "es": "ATENCIÓN",
        "pt": "ATENÇÃO",
        "th": "โปรดให้ความสนใจ",
        "vi": "CHÚ Ý"

    },
    "employeeTypeList":
    {
        "en": ["At this time, if you are a ", "Liberty Mutual Agent/Partner", " OR ", "Ironshore Contractor", " OR ", "GRM employee", " in:"],
        "es": ["En este momento, si eres un ", "Agente / Socio de Liberty Mutual", " O ", "Contratista de Ironshore", " O ", "Empleado de GRM", " en:"],
        "pt": ["Se você é um ", "Agente / parceiro da Liberty Mutual", " OU ", "Fornecedor da Ironshore", " OU ", "colaborador da GRM", " em:"],
        "th": ["ในเวลานี้ถ้าคุณเป็น ", "ตัวแทน / คู่ค้าลิเบอร์ตี้", " หรือ ", "ผู้รับเหมา Ironshore",  " หรือ ", "GRM ลูกจ้าง", " ใน:"],
        "vi": ["Tại thời điểm này, nếu bạn là một ", "Đại lý / Đối tác Tự do", " HOẶC LÀ ", "Nhà thầu Ironshore", " HOẶC LÀ ", "Nhân viên GRM", " trong:"]
    },
    "lpsText":
    {
        "en": ["You will need to use ", "Liberty Password Sync", " to change your password or unlock your account."],
        "es": ["Necesitará usar ", "Liberty Password Sync", " para cambiar su contraseña o desbloquear su cuenta."],
        "pt": ["Você deve usar o ", "Liberty Password Sync", " para alterar / redefinir sua password ou desbloquear sua conta."],
        "th": ["คุณจะต้องใช้ ", "Liberty Password Sync", " เพื่อเปลี่ยน / รีเซ็ตรหัสผ่านของคุณหรือปลดล็อคบัญชีของคุณ"],
        "vi": ["Bạn sẽ cần phải sử dụng ", "Liberty Password Sync", " để thay đổi / đặt lại mật khẩu hoặc mở khóa tài khoản của bạn."]
    },
    "countryList":
    {
        "en": ["Brazil", "Chile", "China", "Colombia", "Ecuador", "Ireland", "Malaysia", "Portugal", "Russia", "Spain", "Venezuela", "Bermuda"],
        "es": ["Brasil", "Chile", "China", "Colombia", "Ecuador", "Irlanda", "Malasia", "Portugal", "Rusia", "España", "Venezuela", "Bermudas"],
        "pt": ["Brasil", "Chile", "China", "Colômbia", "Equador", "Irlanda", "Malásia", "Portugal", "Rússia", "Espanha", "Venezuela", "Bermudas"],
        "th": ["บราซิล", "ชิลี", "จีน", "โคลอมเบีย", "เอกวาดอร์", "ไอร์แลนด์", "มาเลเซีย", "โปรตุเกส", "รัสเซีย", "สเปน", "เวเนซุเอลา", "เบอร์มิวดา"],
        "vi": ["Brasil", "Chile", "Trung Quốc", "Colombia", "Ecuador", "Ai-len", "Malaysia", "Bồ Đào Nha", "Nga", "Tây Ban Nha", "Venezuela", "Bermuda"],
    },
    "userWarningTitle":
    {
        "en": "Please ensure you've done the following:",
        "es": "Por favor, asegúrese de haber hecho lo siguiente:",
        "pt": "Por favor, verifique se você fez o seguinte:",
        "th": "โปรดตรวจสอบให้แน่ใจว่าคุณได้ทำสิ่งต่อไปนี้:",
        "vi": "Vui lòng đảm bảo bạn đã thực hiện như sau:"

    },
    "faqTitle":
    {
        "en": "Frequently Asked Questions",
        "es": "Preguntas frecuentes",
        "pt": "Perguntas frequentes",
        "th": "คำถามที่พบบ่อย",
        "vi": "Các câu hỏi thường gặp"

    },
}
module.exports = ModalText;