let PWText = 
{
    "title":
    {
        "en": "Change OR Forgot Password",
        "es": "Cambiar O Olvidé mi contraseña",
        "pt": "Alterar / Redefinir OU Esqueceu a sua Password",
        "th": "เปลี่ยน / รีเซ็ตหรือลืมรหัสผ่าน",
        "vi": "Thay đổi / Đặt lại HOẶC Quên mật khẩu"

    },
    "subTitle":
        {
            "en": [
                "Note: ",
                "You must follow the same steps to change your password or if you forgot your password."
            ],
            "es": [
                "Nota: ",
                "Debe seguir los mismos pasos para restablecer su contraseña o si olvidó su contraseña."
            ],
            "pt": [
                "Nota: ",
                "Você deve seguir os mesmos passos para redefinir ou recuperar a sua password."
            ],
            "th": [
                "บันทึก: ",
                "คุณต้องทำตามขั้นตอนเดียวกันเพื่อรีเซ็ตรหัสผ่านหรือหากคุณลืมรหัสผ่าน"
            ],
            "vi": [
                "Chú thích: ",
                "Bạn phải làm theo các bước tương tự để đặt lại mật khẩu hoặc nếu bạn quên mật khẩu."
            ]

        },
    "preSteps":
        {
            "en": [
                "Close ALL ",
                "windows and make sure to exit or sign out of all running applications (Outlook, Skype for Business, LibertyCLOUD/Citrix, Zephyr Passport, etc.)",
                "Log out ",
                "completely of any other Windows computers and Virtual Machines connected to the Liberty Network.",
                "For instructions on ",
                "powering off",
                " smartphones and mobile devices, see the 'To Change Your Password' section."
            ],
            "es": [
                "Cierre TODAS ",
                "las ventanas y asegúrese de salir o cerrar sesión en todas las aplicaciones en ejecución (Outlook, Skype for Business, LibertyCLOUD/Citrix, Zephyr Passport, etc.)",
                "Cierre sesión ",
                "completamente de cualquier otra computadora con Windows y máquinas virtuales conectadas a red de Liberty.",
                "Para instrucciones sobre ",
                "El apagado",
                " de teléfonos inteligentes y dispositivos móviles, consulte la sección 'Para cambiar su contraseña'."
            ],
            "pt": [
                "Feche todas as ",
                "janelas e certifique-se de sair de todas as aplicações em execução (Outlook, Skype for Business, LibertyCLOUD/Citrix, Zephyr Passport, etc.)",
                "Fazer Logout ",
                "de qualquer outro computador Windows ou máquina virtual conectado à rede da Liberty.",
                "Para instruções sobre ",
                "desligar",
                " smartphones e dispositivos móveis, consulte a seção 'Para alterar sua Password'."

            ],
            "th": [
                "ปิดทั้งหมด ",
                "Windows และตรวจสอบให้แน่ใจว่าได้ออกหรือลงชื่อออกจากแอปพลิเคชันที่ทำงานอยู่ทั้งหมด (Outlook, Skype for Business, LibertyCLOUD/Citrix, Zephyr Passport, เป็นต้น)",
                "ออกจากระบบ ",
                "คอมพิวเตอร์ Windows เครื่องอื่นและ Virtual Machines ทั้งหมดที่เชื่อมต่อกับ Liberty Network.",
                "สำหรับคำแนะนำเกี่ยวกับ ",
                "ปิดเครื่อง",
                " สมาร์ทโฟนและอุปกรณ์เคลื่อนที่ให้ดูส่วน 'วิธีเปลี่ยนรหัสผ่าน'."
            ],
            "vi": [
                "Đóng tất cả ",
                "cửa sổ và đảm bảo thoát hoặc đăng xuất khỏi tất cả các ứng dụng đang chạy (Outlook, Skype for Business, LibertyCLOUD/Citrix, Zephyr Passport, v.v.)",
                "Đăng xuất ",
                "hoàn toàn của bất kỳ máy tính Windows và Máy ảo nào khác được kết nối với Mạng Liberty.",
                "Để được hướng dẫn về ",
                "tắt nguồn",
                " điện thoại thông minh và thiết bị di động, xem phần 'Để thay đổi mật khẩu của bạn'."
            ]

        },
    "instructions":
    {
        "en": [
            "Enter your ",
            "enterprise email ",
            "and correct CAPTCHA text. Click ",
            "Next",
            "Select the option: ",
            "I forgot my password ",
            "(Select this option whether you want to change your password or if you forgot your password).",
            "Click ",
            "Next",
            "Select your preferred method to verify your account and follow the on-screen instructions. You will receive a verification code you will need to submit in order to change your password.",
            "STOP: ",
            "Only power off your phone after you have entered your verification code successfully.",
            "Once you are on the password change screen, ",
            "power off",
            " any smartphones or mobile devices (iPhone/iPad, Android) you use to connect to the Liberty Network (Email/Outlook, Skype, etc.).",
            "Enter in a new password following our ",
            "Enterprise Password Security Requirements."
        ],
        "es": [
            "Introduzca su ",
            "correo electrónico empresarial ",
            "y corrija el texto CAPTCHA. Haga clic ",
            "Siguiente",
            "Seleccione la opción: ",
            "Olvidé mi contraseña ",
            "(Seleccione esta opción si desea cambiar su contraseña o si olvidó su contraseña).",
            "Haga clic ",
            "Siguiente",
            "Seleccione su método preferido para verificar su cuenta y siga las instrucciones en pantalla. Recibirá un código de verificación que deberá enviar para cambiar su contraseña.",
            "DETENER: ",
            "Solo apague su teléfono después de haber ingresado su código de verificación correctamente.",
            "Una vez que esté en la pantalla de cambio de contraseña, ",
            "apagar",
            " cualquier teléfono inteligente o dispositivo móvil (iPhone / iPad, Android) que utilice para conectarse a Liberty Network (correo electrónico / Outlook, Skype, etc.).",
            "Ingrese una nueva contraseña siguiendo nuestros ",
            "Requisitos de seguridad de contraseña de empresa."
        ],
        "pt": [
            "Insira o seu ",
            "email corporativo ",
            "e corrija o texto CAPTCHA. Clique ",
            "Próximo",
            "Selecione a opção: ",
            "Esqueci a minha password ",
            "(Selecione esta opção se você deseja alterar / redefinir sua password ou se esqueceu sua password).",
            "Clique ",
            "Próximo",
            " Selecione seu método preferido para verificar sua conta e siga as instruções no ecrã. Você receberá um código de verificação que precisará enviar para alterar sua password.",
            "PARE: ",
            "Apenas desligue seu smartphone depois de inserir seu código de verificação com sucesso.",
            " Quando estiver no ecrã de mudança de password,",
            "desligue",
            " quaisquer smartphones ou dispositivos móveis (iPhone / iPad, Android) que você usa para se conectar à rede Liberty (Email / Outlook, Skype, etc.).",
            "Digite uma nova password seguindo os ",
            "Requisitos de segurança de password da companhia."
        ],
        "th": [
            "ใส่",
            " อีเมลขององค์กร ของคุณ ",
            "และแก้ไขข้อความ CAPTCHA คลิก ",
            "ต่อไป",
            "เลือกตัวเลือก:",
            " ฉันลืมรหัสผ่าน ",
            "(เลือกตัวเลือกนี้ไม่ว่าคุณต้องการเปลี่ยน / รีเซ็ตรหัสผ่านหรือหากคุณลืมรหัสผ่าน).",
            "คลิก ",
            "ต่อไป",
            "เลือกวิธีที่คุณต้องการเพื่อยืนยันบัญชีของคุณและทำตามคำแนะนำบนหน้าจอ คุณจะได้รับรหัสยืนยันคุณจะต้องส่งเพื่อเปลี่ยนรหัสผ่าน.",
            "หยุด: ",
            "ปิดโทรศัพท์หลังจากที่คุณป้อนรหัสยืนยันของคุณสำเร็จเท่านั้น.",
            "เมื่อคุณอยู่บนหน้าจอเปลี่ยนรหัสผ่าน ",
            "ปิดเครื่อง",
            " สมาร์ทโฟนหรืออุปกรณ์มือถือใด ๆ (iPhone / iPad, Android) ที่คุณใช้เชื่อมต่อกับ Liberty Network (อีเมล / Outlook, Skype และอื่น ๆ ).",
            "ใส่รหัสผ่านใหม่ตามเรา ",
            "ข้อกำหนดด้านความปลอดภัยของรหัสผ่านองค์กร."

        ],
        "vi": [
            "Nhập thông tin của bạn ",
            "email doanh nghiệp ",
            "và nhập chính xác chuỗi CAPTCHA. Nhấp chuột ",
            "Kế tiếp",
            "Chọn tùy chọn: ",
            "Tôi quên mật khẩu của tôi ",
            "(Chọn tùy chọn này cho dù bạn muốn thay đổi / đặt lại mật khẩu hoặc nếu bạn quên mật khẩu).",
            "Nhấp chuột ",
            "Kế tiếp",
            "Chọn phương thức ưa thích của bạn để xác minh tài khoản của bạn và làm theo các hướng dẫn trên màn hình. Bạn sẽ nhận được mã xác minh bạn cần gửi để thay đổi mật khẩu của mình.",
            "DỪNG LẠI: ",
            "Chỉ tắt nguồn điện thoại của bạn sau khi bạn nhập mã xác minh thành công.",
            "Khi bạn đang ở trên màn hình thay đổi mật khẩu, ",
            "tắt nguồn",
            " mọi điện thoại thông minh hoặc thiết bị di động (iPhone / iPad, Android) bạn sử dụng để kết nối với Mạng Liberty (Email / Outlook, Skype, v.v.).",
            "Nhập mật khẩu mới theo những điều kiện của chúng tôi ",
            "Yêu cầu bảo mật mật khẩu doanh nghiệp."
            
        ]
    },
    "postSteps":{
        "en": [
            "After ",
            "you have changed your password, please wait 2 minutes. Then: ",
            "Press ",
            "Control + Alt + Delete",
            "Select ",
            "Lock Computer",
            "Press ",
            "Control + Alt + Delete ",
            "again",
            "Enter your ",
            "new ",
            "password"
        ],
        "es": [
            "Después ",
            "Usted ha restablecido su contraseña, por favor espere 2 minutos. Entonces: ",
            "Presione ",
            "Control + Alt + Supr",
            "Seleccionar ",
            "Bloquear la computadora",
            "Presione ",
            "Control + Alt + Supr ",
            "otra vez",
            "introduzca su ",
            "nueva ",
            "Contraseña"
        ],
        "pt": [
            "Depois de ",
            "você redefinir sua password, aguarde 2 minutos. Então:",
            "Pressione ",
            "Control + Alt + Delete",
            "Selecione ",
            "Bloquear Computador",
            "Pressione ",
            "Control + Alt + Delete ",
            "novamente",
            "Insira a sua ",
            "Nova ",
            "password"
        ],
        "th": [
            "หลังจาก ",
            "คุณรีเซ็ตรหัสผ่านของคุณโปรดรอ 2 นาที แล้ว: ",
            "กด ",
            "Control + Alt + Delete",
            "เลือก ",
            "ล็อคคอมพิวเตอร์",
            "กด ",
            "Control + Alt + Delete ",
            "อีกครั้ง",
            "ใส่ของคุณ ",
            "ใหม่ ",
            "password"

        ],
        "vi": [
            "Sau khi ",
            "bạn đã đặt lại mật khẩu, vui lòng đợi 2 phút. Sau đó: ",
            "nhấn ",
            "Ctrl + Alt + Del",
            "Lựa chọn ",
            "Khóa máy tính",
            "Press ",
            " Ctrl + Alt + Del ",
            "lần nữa",
            "Nhập vào của bạn ",
            "Mới ",
            "password"

        ],
    },
    "mobilePostStep":
    {
        "en": ["Power on ", "your smartphone or mobile device and enter in your new enterprise password for Liberty Mutual applications as required (example: Outlook, Skype, etc.)"],
        "es": ["Encienda ", "su teléfono inteligente o dispositivo móvil e introduzca su nueva contraseña de empresa para las aplicaciones de Liberty Mutual según sea necesario (ejemplo: Outlook, Skype, etc.)"],
        "pt": ["Ligar ", "seu smartphone ou dispositivo móvel e digite sua nova password corporativa para os aplicativos da Liberty Mutual, conforme necessário (por exemplo: Outlook, Skype, etc.)"],
        "th": ["เปิดเครื่อง ", "สมาร์ทโฟนหรืออุปกรณ์มือถือของคุณและป้อนรหัสผ่านองค์กรใหม่ของคุณสำหรับแอพพลิเคชั่น Liberty Mutual ตามต้องการ (ตัวอย่าง: Outlook, Skype, ฯลฯ )"],
        "vi": ["Bật nguồn ", "điện thoại thông minh hoặc thiết bị di động của bạn và nhập mật khẩu doanh nghiệp mới cho các ứng dụng Liberty Mutual theo yêu cầu (ví dụ: Outlook, Skype, v.v.)"]
    },
    "beforePWChange":
    {
        "en": "Before Changing Your Password",
        "es": "Antes de cambiar su contraseña",
        "pt": "Antes de alterar a sua Password",
        "th": "ก่อนเปลี่ยนรหัสผ่าน",
        "vi": "Trước khi thay đổi mật khẩu của bạn"

    },
    "toPWChange":
    {
        "en": "To Change Your Password",
        "es": "Para cambiar su contraseña",
        "pt": "Para alterar a sua Password",
        "th": "เพื่อเปลี่ยนรหัสผ่านของคุณ",
        "vi": "Để thay đổi mật khẩu của bạn"

    },
    "afterPWChange":
    {
        "en": "After Changing Your Password",
        "es": "Después de cambiar su contraseña",
        "pt": "Depois de alterar a sua Password",
        "th": "หลังจากเปลี่ยนรหัสผ่าน",
        "vi": "Để thay đổi mật khẩu của bạn"

    },
    "clickButtonInstr":
    {
        "en": "Click the 'Manage Password' button below and follow these instructions:",
        "es": "Haga clic en el botón 'Administrar contraseña' a continuación y siga estas instrucciones:",
        "pt": "Clique no botão 'Gerir Password' abaixo e siga estas instruções:",
        "th": "คลิกปุ่ม 'จัดการรหัสผ่าน' ด้านล่างและทำตามคำแนะนำเหล่านี้:",
        "vi": "Nhấp vào nút 'Quản lý mật khẩu' bên dưới và làm theo các hướng dẫn sau:"

    },
    "windowsUsers":
    {
        "en": "Windows & Non-Mac Users",
        "es": "Usuarios de Windows y no Mac",
        "pt": "Utilizadores Windows e não-Mac",
        "th": "ผู้ใช้ Windows และไม่ใช่ Mac",
        "vi": "Người dùng Windows và không dùng Mac"

    },
    "vdeUsers":
    {
        "en": ["Note: ", "LibertyCLOUD Workspace (VDE/VDI) users do not need to Control + Alt + Delete from within their VM"],
        "es": ["Nota: ", "Los usuarios de LibertyCLOUD Workspace (VDE / VDI) no necesitan Control + Alt + Supr desde su VM"],
        "pt": ["Nota: ", "Os utilizadores do LibertyCLOUD Workspace (VDE / VDI) não precisam fazer Control + Alt + Delete dentro da sua VM"],
        "th": ["บันทึก: ", "ผู้ใช้ LibertyCLOUD Workspace (VDE / VDI) ไม่จำเป็นต้อง Control + Alt + Delete จากภายใน VM"],
        "vi": ["Chú thích: ", "Người dùng LibertyCLOUD Workspace (VDE / VDI) không cần phải Control + Alt + Delete từ bên trong VM của họ "],

    },
    "macUsers":
    {
        "en": "Mac Users",
        "es": "Usuarios de Mac",
        "pt": "Utilizadores Mac",
        "th": "ผู้ใช้ Mac",
        "vi": "Người dùng Mac"

    },
    "macInstr":
    {
        "en": ["Follow Instructions found ", "here."],
        "es": ["Siga las instrucciones encontradas ", "aquí."],
        "pt": ["Siga as instruções encontradas ", "aqui."],
        "th": ["ทำตามคำแนะนำที่พบ ", "ที่นี่."],
        "vi": ["Thực hiện theo hướng dẫn ", "tại đây."],

    }

}
module.exports = PWText;