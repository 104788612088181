import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PWText from '../../text-content/PWText';
import Links from '../../text-content/Links';
import { getLang } from '../../actions/langActions';

const styles = theme => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '300',
    color: '#1a1446'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  title: {
    background: '#78E1E1'
  },
  details: {
    textAlign: 'center',
    display: 'block',
    width:'100%',
  },
  subDetails: {
    textAlign: 'center',
    display: 'block',
    width:'100%',
  },
  smallNote: {
    fontSize: '9pt'
  },
  importantNote: {
    border: '1px solid #FF0000',
    borderRadius: '5px',
    padding: '7px',
    margin: '5px',
    color: '#8b0000',
    width: 'auto',
    textAlign: 'center',
  },
  mobileNote: {
    border: '1px solid #1a1446',
    borderRadius: '5px',
    padding: '7px',
    margin: '5px',
    color: '#1a1446',
    width: 'auto',
    textAlign: 'center',
  },
  infoIcon: {
    color: '#78E1E1',
    backgroundColor: '#1a1446',
    borderRadius: '100%',
    padding: ' 0 1px 0 1px'
  },
});

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null,
  };
  componentWillReceiveProps(){
    this.setState({
      expanded: 'panel2'
  })
  }
  componentDidMount(){
    this.setState({
        expanded: null
    })
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    let userLang = getLang();
    let preSteps = PWText.preSteps[userLang];
    let instructions = PWText.instructions[userLang];
    let postSteps = PWText.postSteps[userLang];
    let beforePWChange = PWText.beforePWChange[userLang];
    let toPWChange = PWText.toPWChange[userLang];
    let afterPWChange = PWText.afterPWChange[userLang];
    let clickButtonInstr = PWText.clickButtonInstr[userLang];
    let windowsUsers = PWText.windowsUsers[userLang];
    let macUsers = PWText.macUsers[userLang];
    let macInstr = PWText.macInstr[userLang];
    let vdeUsers = PWText.vdeUsers[userLang];
    let mobilePostStep = PWText.mobilePostStep[userLang];
    return (
      <div className={classes.root}>
        <ExpansionPanel  expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')} defaultExpanded>
          <ExpansionPanelSummary className={classes.title} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{beforePWChange}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={'span'}>
            <ol>
              <li><strong>{preSteps[0]}</strong>{preSteps[1]}</li>
              <li><strong>{preSteps[2]}</strong>{preSteps[3]}</li>
              <li>{preSteps[4]}<strong>{preSteps[5]}</strong>{preSteps[6]}</li>
            </ol>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
          <ExpansionPanelSummary className={classes.title} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{toPWChange}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={'span'}>
              <p>{clickButtonInstr}</p>
            <ol>
              <li>{instructions[0]}<strong>{instructions[1]}</strong>{instructions[2]}<strong>{instructions[3]}</strong>.</li>
              <li>{instructions[4]}<strong>{instructions[5]}</strong><em>{instructions[6]}</em></li>
              <li>{instructions[7]}<strong>{instructions[8]}</strong>.</li>
              <li>{instructions[9]}
                  <div className={classes.importantNote}><i className="fas fa-exclamation-triangle"></i> <strong>{instructions[10]}</strong>{instructions[11]} <i className="fas fa-exclamation-triangle"></i></div>
              </li>
              <li>{instructions[12]}<strong>{instructions[13]}</strong>{instructions[14]}</li>
              <li>{instructions[15]}<a href={Links.enterprisePWreq} target="_blank" rel="noopener noreferrer">{instructions[16]}</a></li>
            </ol>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')}>
          <ExpansionPanelSummary className={classes.title} expandIcon={<ExpandMoreIcon />}>
            <Typography component={'span'} className={classes.heading}>{afterPWChange}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography component={'span'} className={classes.root}> 
          <div className={classes.details}><strong>{postSteps[0]}</strong>{postSteps[1]}</div>
          <div className ='InstrRow row dualCol'>
            <div className="postInstrCol">
              <h3 className='card-sub-head'>{windowsUsers}</h3>
              <ol>
                <li>{postSteps[2]}<strong>{postSteps[3]}</strong></li>
                <li>{postSteps[4]}<strong>{postSteps[5]}</strong></li>
                <li>{postSteps[6]}<strong>{postSteps[7]}</strong>{postSteps[8]}</li>
                <li>{postSteps[9]}<strong>{postSteps[10]}</strong>{postSteps[11]}</li>
              </ol>
            </div>
            <div className="postInstrCol col-line">
              <h3 className='card-sub-head'>{macUsers}</h3>
              <p>{macInstr[0]}<a href={Links.macInstructions} target="_blank" rel="noopener noreferrer">{macInstr[1]}</a></p>
            </div>
          </div>
          <div className={classes.subDetails}>
          <div className={classes.mobileNote}><span className={classes.infoIcon}><i className="fas fa-info-circle"></i></span><strong> {mobilePostStep[0]}</strong>{mobilePostStep[1]} <span className={classes.infoIcon}><i className="fas fa-info-circle"></i></span></div>
            <div className={classes.smallNote}><em><strong>{vdeUsers[0]}</strong>{vdeUsers[1]}</em></div>
          </div>
          </Typography>
        </ExpansionPanelDetails>
        </ExpansionPanel>
        
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);